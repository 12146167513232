import create from "zustand";
import { EquipmentStatusCreateDto, EquipmentStatusDto, EquipmentStatusDtoPaginatedResponse, EquipmentStatusEnum, EquipmentStatusUpdateDto } from "../api/Ferrolog";
import { ferrologApi } from "../App";
import { Pagination } from "../interface/PaginationInterface";
import { getEmptyPaginationContainer } from "./StoreHelpers";

type State = {
    SelectedEquipmentId: number
    setSelectedEquipmentId: (equipmentId: number) => void
    Pagination: Pagination
    setPagination: (pagination: Pagination) => void
    PaginationContainer: EquipmentStatusDtoPaginatedResponse
    fetch: () => void
    fetchById: (id: number) => Promise<EquipmentStatusDto>
    create: (equipmentStatus: EquipmentStatusCreateDto) => Promise<void | EquipmentStatusDto>
    update: (id: number, equipmentStatus: EquipmentStatusUpdateDto) => Promise<void | EquipmentStatusDto>

    readableStatus: (status: EquipmentStatusEnum) => string
}

export const readableLookupDict = {
    'Calibrated': 'Calibrated',
    'CalibrationRequired': 'Calibration Required',
    'SentForCalibration': 'Sent for Calibration',
    'Approved': 'Approved',
    'OnHold': 'On Hold',
    'Discarded': 'Discarded',
    'Purchased': 'Purchased',
}

export const equipmentStatusStore = create<State>((set, get) => ({
    SelectedEquipmentId: 0,
    setSelectedEquipmentId: equipmentId => {
        set({SelectedEquipmentId: equipmentId})
        get().fetch()
    },
    Pagination: { CurrentPage: 1, PageSize: 10 },
    setPagination: pagination => set({Pagination: pagination}),
    
    PaginationContainer: { items: [], currentPageItemCount: 0, paginationFilter: { currentPage: 1, pageSize: 10 }, totalItemCount: 0, totalPageCount: 0 },

    fetch: () => {
        if (get().SelectedEquipmentId === 0)
            return;

        set({PaginationContainer: getEmptyPaginationContainer()})

        ferrologApi.equipmentStatusesList({...get().Pagination, equipmentId: get().SelectedEquipmentId})
            .then(response => { set({ PaginationContainer: response.data }) })
            .catch(error => console.error(error))
    },

    create: (equipmentStatus: EquipmentStatusCreateDto) => new Promise<void | EquipmentStatusDto>((resolve, reject) => 
        ferrologApi.equipmentStatusesCreate(equipmentStatus)
            .then(result => {
                get().fetch()
                resolve(result.data)
            }).catch(e => reject(e))),

    fetchById: (id: number) => ferrologApi.companyDetail(id)
        .then(response => response.data),

    update: (id, equipmentStatus: EquipmentStatusUpdateDto) => ferrologApi.equipmentStatusesUpdate(id, equipmentStatus)
        .then(() => get().fetch()),

    readableStatus: (status) =>  status in readableLookupDict ? readableLookupDict[status] : 'Undefined status',
    
}))