import { Button, debounce, MenuItem, Select, Typography } from "@mui/material"
import { PropsWithChildren, useEffect, useState } from "react"
import { FileTypes } from "../../constants/FileTypes"
import FileTable from "../../file/FileTable"
import { fileStore } from "../../stores/FileStore"
import dragDropStyle from "./dragDropStyle"
import ferrologTableStyle from "../table/ferrologTableStyle"
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import moment from "moment"
import {  FilesPaginatedResponse } from "../../api/Ferrolog"
import { ferrologApi } from "../../App"

type DragAndDropFileProps = {
    entityId: number,
    entityName: string,
    edit: boolean,
    children: React.ReactNode
}

export const DragAndDrop: React.FC<PropsWithChildren<DragAndDropFileProps>> = (props) => {
    const { entityId, entityName, edit, children } = props
    const { Pagination } = fileStore()

    const [fileName, setFileName] = useState('click here to upload files')
    const [drawBackground, setDrawBackground] = useState(false)
    const [fileType, setFileType] = useState('')
    const [fileInput, setFileInput] = useState<File>()
    const [fileList, setFileList] = useState<FilesPaginatedResponse>({
        currentPageItemCount: 0,
        items: [],
        paginationFilter: { currentPage: 1, pageSize: 10 },
        totalItemCount: 0,
        totalPageCount: 1,
    })

    const checkFileTypes = (inputValue: string) => {
        var fileTypes: any = {
            'jpg': FileTypes.PICTURES,
            'jpeg': FileTypes.PICTURES,
            'png': FileTypes.PICTURES,
            'pdf': FileTypes.PDF,
            'doc': FileTypes.OFFICE,
            'docx': FileTypes.OFFICE,
            'xls': FileTypes.OFFICE,
            'xlsx': FileTypes.OFFICE,
            'ppt': FileTypes.OFFICE,
            'pptx': FileTypes.OFFICE,
        }

        setFileType(fileTypes[inputValue] ?? FileTypes.OTHERS)
    }

    useEffect(() => {
        getFileList()
    }, [entityId, entityName])

    const getFileList = () => {
        ferrologApi.filesDetail2(entityName, entityId, Pagination)
            .then(response => setFileList(response.data))
            .catch(Error)
    }

    const handleFileChange = (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement
        const file: File = (target.files as FileList)[0]
        setFileInput(file)
        setFileName(file.name)

        checkFileTypes(getFileName(file))
    }

    const getFileName = (file: File) => {
        var inputFileType = file.name.substr(file.name.lastIndexOf('.') + 1)

        return inputFileType;
    }

    const dropHandler = (event: any) => {
        if (edit)
            setDrawBackground(false)

        handleSetDrawBackgroundDebounced(false)

        let file = event.dataTransfer.files[0]
        setFileName(file.name)
        setFileInput(file)
        checkFileTypes(getFileName(file))

        event.preventDefault();
    }

    const handleSetDrawBackgroundDebounced = debounce(value => {
        if (edit)
            setDrawBackground(value)

    }, 500)

    const allowDrop = (event: React.DragEvent<HTMLDivElement>) => {
        if (edit)
            setDrawBackground(true)

        handleSetDrawBackgroundDebounced(true)

        event.stopPropagation();
        event.preventDefault();

    }

    const saveFile = () => {
        let createFile = {
            file: fileInput,
            fileType: fileType,
            uploadByUser: 'TestString',
            uploadByDate: moment().format('YYYY-MM-DD HH:mm:ss')
        }

        ferrologApi.filesCreate(entityName, entityId, createFile)
            .then((response) => {
                getFileList()
            })

        setFileName('click here to upload files')
        setFileType('')
    }

    return (
        <div onDragOver={allowDrop} onDrop={dropHandler} onDragLeave={() => handleSetDrawBackgroundDebounced(false)} style={drawBackground ? dragDropStyle.backgroundTransparent : { height: '100%', backgroundColor: 'transparent' }}>
            {children}
            {
                edit &&
                <div>
                    <div style={ferrologTableStyle.downloadButtonContainer}>
                            <input type="file" name="uploadfile" id={entityName} style={{ display: 'none' }} onDrop={dropHandler} onChange={handleFileChange} />
                            <label htmlFor={entityName} style={{ marginTop: 10 }}>{fileName}</label>

                        <Select style={{ width: 200, height: 30, margin: 5 }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fileType}
                            onChange={e => setFileType(e.target.value)}>
                            <MenuItem value={FileTypes.PICTURES}>{FileTypes.PICTURES}</MenuItem>
                            <MenuItem value={FileTypes.PDF}>{FileTypes.PDF}</MenuItem>
                            <MenuItem value={FileTypes.CERTIFICATE}>{FileTypes.CERTIFICATE}</MenuItem>
                            <MenuItem value={FileTypes.OFFICE}>{FileTypes.OFFICE}</MenuItem>
                            <MenuItem value={FileTypes.OTHERS}>{FileTypes.OTHERS}</MenuItem>
                        </Select>
                        <Button style={{ margin: 5 }}
                            variant="contained"
                            color='primary'
                            onClick={() => saveFile()}>
                            Upload File
                        </Button>
                    </div>
                        {drawBackground ?

                            <div style={dragDropStyle.dragDropCenter}>
                                <div style={dragDropStyle.dragDropBox}>
                                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 50 }}>
                                        <CloudUploadIcon color="success" fontSize='large' sx={{ margin: '5px' }} />
                                        <Typography variant="caption" component={'span'} style={dragDropStyle.dragDropText} color='primary' align='center'>Drag 'n' drop files any where</Typography>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <FileTable
                                    pagination={Pagination}
                                    fetchFile={getFileList}
                                    getPaginationContainer={fileList}
                                />
                            </>

                        }
                </div>
            }
        </div>
    )
}