import React from 'react'
import { StandardTextFieldProps, TextField } from "@mui/material"

/**
 * Extend properties
 */
export interface DateTimeTextFieldProps extends StandardTextFieldProps {
    value: string | null | undefined
}

/**
 * Password input
 * @param props Properties
 */
export const DateTimeTextField: React.FunctionComponent<DateTimeTextFieldProps> = (props) => {
    const {value, ...otherProps} = props

    let finalProps = Object.assign({
        type: 'datetime-local',
        value: (value ?? '').substring(0,16),
    }, otherProps)

    return (
        <TextField {...finalProps} >
            {props.children}
        </TextField>
    )
}