import { TableCell, TableRow, useMediaQuery, useTheme } from "@mui/material"
import { InventoryLocationDto } from "../api/Ferrolog"
import { pageContainerStore } from "../components/pageContainer/PageContainerStore"
import { countryCodesStore } from "../stores/CountryCodeStore"

export default function InventoryLocationTableRow(props: { row: InventoryLocationDto }) {
    const { row } = props

    const { setSelectedRowId } = pageContainerStore()
    const { getCountryCodeAlpha2ById } = countryCodesStore()
    const theme = useTheme()

    const showAll = useMediaQuery(theme.breakpoints.up('sm'))

    const ShowInventoryLocationTableRow = () =>
        <>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.street}</TableCell>
            <TableCell>{row.postcode}</TableCell>
            {
                showAll
                    ? <>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{getCountryCodeAlpha2ById(row.countryId)}</TableCell>
                    </>
                    : null
            }
        </>

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            key={row.id}
            onClick={() => setSelectedRowId(row.id)}
            style={{ cursor: 'pointer' }}
        >
            {
                <ShowInventoryLocationTableRow />
            }
        </TableRow>
    )
}