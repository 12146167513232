import { TableCell, TableRow, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { CompanyDto } from "../../api/Ferrolog";
import { pageContainerStore } from "../../components/pageContainer/PageContainerStore";

interface CompanyTableRowProps {
    row: CompanyDto
}

export default function CompanyTableRow(props: CompanyTableRowProps) {
    const { row } = props
    const theme = useTheme()

    const showAll = useMediaQuery(theme.breakpoints.up('sm'))

    const { setSelectedRowId } = pageContainerStore()

    const ShowEquipmentTableRow = () =>
        <>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.address}</TableCell>
            <TableCell>{row.zip}</TableCell>
            {
                showAll
                    ? <>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>{row.phoneNr}</TableCell>
                        <TableCell>{row.web}</TableCell>
                        <TableCell>{row.email}</TableCell>
                    </>
                    : null
            }
        </>

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={row.id}
                onClick={() => setSelectedRowId(row.id)}>
                {ShowEquipmentTableRow()}
            </TableRow>
        </React.Fragment>
    )
}