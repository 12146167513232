import {
    TransformComponent,
    TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch"
import { useEffect, useState } from "react"
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { useLocation, useNavigate } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close'

export default function ZoomPage() {
    const [windowSize, setWindowSize] = useState(getWindowSize())
    const [zoom, setZoom] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        function handleWindowResize()
        {
            setWindowSize(getWindowSize())
        }
        
        window.addEventListener('resize', handleWindowResize)

        return () =>  window.removeEventListener('resize', handleWindowResize)
    }, [])

    const zoomInPage = (zoomIn :any) => setZoom(true)
    
    const PictureUrl: unknown = location.state

    return (
        <TransformWrapper
            initialPositionX={100}
            initialPositionY={100}
            initialScale={0.5}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                    <div className="tools" style={{ flex: 1, display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <button onClick={() => zoomInPage(zoomIn())}><ZoomInIcon /></button>
                        <button onClick={() => zoomOut()}><ZoomOutIcon/></button>
                        <button onClick={() => navigate(-1)}><CloseIcon/></button>
                    </div>
                    <div style={{ marginTop: '5px', height: windowSize.innerHeight, marginLeft: zoom ? '0%' : '35%' }}>
                        <TransformComponent contentStyle={{ height: zoom ? windowSize.innerHeight : '100%', width: zoom ? windowSize.innerWidth : '100%' }} >
                            <img  src={PictureUrl as string} style={{ alignItems: 'center', width: '100%', height: '100%' }} />
                        </TransformComponent>
                    </div>
                </>
            )}
        </TransformWrapper>
    )
}

function getWindowSize() {
    const { innerWidth, innerHeight } = window
    return { innerWidth, innerHeight }
}