import { Button, IconButton, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import ferrologTableStyle from "../../components/table/ferrologTableStyle"
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { fileStore } from "../../stores/FileStore"
import Swal from "sweetalert2"
import moment from "moment"
import { FileTypes } from "../../constants/FileTypes"

export default function FileTableRow(props: { row: any, fetch:() => void }) {
    const { row, fetch } = props
    const [open, setOpen] = React.useState(false)
    const theme = useTheme()

    const showDropDown = useMediaQuery(theme.breakpoints.down('xl'))
    const showAll = useMediaQuery(theme.breakpoints.up('xl'))
    const showSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))
    const showMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

    const { deleteFile, fetchById } = fileStore()
    const env = window.navigator.language

    const deleteFileByRow = (id: number) => {
        Swal.fire({
            title: 'Are you sure you  want to delete file?',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            if (result.isConfirmed) 
                deleteFile(id)

            fetch()
        })
    }

    const downloadFile = (id: number) => { fetchById (id)}

    const ShowTableRow = () =>
        <>
            {IncludeDropDown()}
            <TableCell>
                <div style={{ flexDirection: 'row' }}>
                    <Button onClick={() => downloadFile(row.id)} startIcon={<DownloadIcon />}>{row.fileName}</Button>
                </div>
            </TableCell>
            {IncludeSmallScreenRows()}
            {IncludeMediumScreenRows()}
            {IncludeFullScreenRows()}
        </>

    const IncludeDropDown = () => {
        if (!showDropDown)
            return null

        return (
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
        )
    }

    const IncludeMediumScreenRows = () => {
        if (!showMediumScreen)
            return null

        return (
            <TableCell align="right">{moment(row.uploadByDate).toDate().toLocaleDateString()}</TableCell>
        )
    }

    const IncludeSmallScreenRows = () => {
        if (!showSmallScreen)
            return null

        return (
            <>
                <TableCell align="right"> {row.uploadByUser }
                </TableCell>
            </>
        )
    }
    const IncludeFullScreenRows = () => {
        if (!showAll)
            return null

        return (
            <>
                <TableCell align="right">
                    <IconButton aria-label="delete" color='error' onClick={() => deleteFileByRow(row.id)}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </>
        )
    }
    const IncludeDropDownSmallScreenList = () => {
        if (showSmallScreen)
            return null

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h1 style={{ color: 'black', fontSize: 15 }}>Upload by User</h1>
                <Typography component={'span'} variant="subtitle1" style={ferrologTableStyle.tableListText}>{row.uploadByUser}</Typography>
            </div>
        )
    }

    const IncludeDropDownMediumScreenList = () => {
        if (showMediumScreen)
            return null

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h1 style={{ color: 'black', fontSize: 15 }}>Upload Date</h1>
                <Typography component={'span'} variant="subtitle1" style={ferrologTableStyle.tableListText}>{moment(row.uploadByDate).locale(env).format('L')}</Typography>
            </div>

        )
    }

    const IncludeDropDownFullScreenList = () => {
        if (showAll)
            return null

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton aria-label="delete" color='error' onClick={() => deleteFileByRow(row.id)}>
                    <DeleteIcon />
                </IconButton>
            </div>
        )
    }

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={row.type}>
                {
                    row.fileType !== FileTypes.PHOTODATASET &&
                    <ShowTableRow />
                }
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow key={row.id}>
                                    <div>
                                        {IncludeDropDownSmallScreenList()}
                                        {IncludeDropDownMediumScreenList()}
                                        {IncludeDropDownFullScreenList()}
                                    </div>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}