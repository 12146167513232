import { Avatar, TableCell, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { EquipmentDto } from "../../api/Ferrolog"
import { pageContainerStore } from "../../components/pageContainer/PageContainerStore"
import { Cateogries } from "../../constants/Cateogries"
import { equipmentStatusStore } from "../../stores/EquipmentStatusStore";
import { calculateNextCalibration, hasValidCalibration, showCalibrationNotification } from "../EquipmentHelpers"

interface EquipmentTableRowProps {
    row: EquipmentDto
}

export default function EquipmentTableRow(props: EquipmentTableRowProps) {
    const theme = useTheme()

    const { row } = props

    const { setSelectedRowId } = pageContainerStore()
    const { setSelectedEquipmentId } = equipmentStatusStore()

    const showAll = useMediaQuery(theme.breakpoints.up('xl'))
    const showMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

    const getCategory = (categoryEnumValue: number) => {
        const CateogryList: any = {
            1: Cateogries.MEASUREMENT,
            2: Cateogries.VALIDATION,
            3: Cateogries.TOOL,
        }

        return CateogryList[categoryEnumValue] ?? ''
    }

    const nextCalibration = new Date(calculateNextCalibration(row))

    const ShowEquipmentTableRow = () =>
        <>
            <TableCell>
                <div style={{ height: '20px', width: '20px', borderRadius: '50%', backgroundColor: hasValidCalibration(row) ? 'green' : 'red' }}></div>
            </TableCell>
            <TableCell>
                {showCalibrationNotification(row) &&
                    <Avatar sx={{ bgcolor: 'transparent' }} variant="square" src={window.location.origin + "/traingle.png"} style={{ height: 20, width: 20 }}>
                    </Avatar>}
            </TableCell>
            <TableCell>
                <Typography variant="subtitle1">{row.equipmentId}</Typography>
            </TableCell>
            {
                showMediumScreen
                    ? <>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                            <Typography variant="subtitle1">{getCategory(row.cateogry!)}</Typography>
                        </TableCell>
                    </>
                    : null
            }
            {
                showAll
                    ? <>
                        <TableCell></TableCell>
                        <TableCell>
                            {
                                !isNaN(nextCalibration.valueOf()) &&
                                <Typography variant="subtitle1">{nextCalibration.toLocaleDateString()}</Typography>
                            }
                        </TableCell>
                    </>
                    : null
            }
        </>

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={row.id}
                onClick={() => {
                    setSelectedRowId(row.id)
                    setSelectedEquipmentId(row.id!)
                }}
                style={{ cursor: 'pointer' }}
            >
                {
                    <ShowEquipmentTableRow />
                }
            </TableRow>
        </React.Fragment>
    )
}