import { CalibrationIntervalEnum, EquipmentCreateDto, EquipmentDto, EquipmentDtoPaginatedResponse, EquipmentStatusDto, EquipmentStatusEnum, EquipmentUpdateDto } from '../api/Ferrolog'
import create from 'zustand'
import { Pagination } from '../interface/PaginationInterface'
import produce from 'immer'
import { ferrologApi } from '../App'

type State = {
    Pagination: Pagination
    setPagination: (pagination: Pagination) => void
    PaginationContainer: EquipmentDtoPaginatedResponse
    fetch: () => void
    fetchById: (id: number) => Promise<EquipmentDto>
    create: (equipmnet: EquipmentCreateDto) => Promise<void>
    updateEquipment: (id: number, equipment: EquipmentUpdateDto) => Promise<void>
    getEmptyEquipment: () => EquipmentCreateDto
    getSelectedEquipment: (id?: number) => EquipmentDto | undefined
    addEquipmentStatus: (equipmentId: number, status: EquipmentStatusDto) => void
}

export const equipmentStore = create<State>((set, get) => ({
    Pagination: { CurrentPage: 1, PageSize: 10, searchString: '' },
    setPagination: pagination => set({Pagination: pagination}),
    
    PaginationContainer: { items: [], currentPageItemCount: 0, paginationFilter: { currentPage: 1, pageSize: 10 }, totalItemCount: 0, totalPageCount: 0 },

    fetch: () => {
        ferrologApi.equipmentsList(get().Pagination)
            .then(response => { set({ PaginationContainer: response.data }) })
            .catch(Error => console.log('display error', Error))
    },

    create: (equipmnet) => ferrologApi.equipmentsCreate(equipmnet)
        .then(() => get().fetch()),

    fetchById: (id: number) => ferrologApi.equipmentsDetail(id)
        .then(response => response.data ),

    updateEquipment: (id, equipment) => ferrologApi.equipmentsUpdate(id, equipment).then(() => get().fetch()),

    getEmptyEquipment: () => ({
        description: '',
        equipmentId: '',
        lastCalibration: '',
        locked: false,
        cateogry: 0,
        lockedByUser: '',
        manufacture: '',
        manufactureDesignation: '',
        inventoryLocationId: 0,
        storageInTheLocation: '',
        calibrationInterval: 1,
        calibrationIntervalType: CalibrationIntervalEnum.Year,
        notificationInterval: 3,
        notificationIntervalType: CalibrationIntervalEnum.Month,
    }),

    getSelectedEquipment: id => get().PaginationContainer.items?.find(i => i.id === id),

    addEquipmentStatus: (equipmentId, status) => {
        set(produce((state: State) => {
            let equipment = state.PaginationContainer?.items?.find(i => i.id === equipmentId)

            if (!equipment)
                return;

            if(status.status === EquipmentStatusEnum.Purchased)
                equipment.equipmentStatuses?.push(status)
            else
                equipment.equipmentStatuses?.unshift(status)
            
            if(status.status === EquipmentStatusEnum.Calibrated)
                equipment.lastCalibration = status.validFromDate
        }))
    },
}))

