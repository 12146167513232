import React from 'react'
import { Select, SelectProps } from "@mui/material"
import { CalibrationIntervalEnum } from '../api/Ferrolog'

/**
 * Extend properties
 */
export interface CalibrationIntervalSelectProps extends SelectProps {
    value: CalibrationIntervalEnum | string | null | undefined
}

/**
 * Password input
 * @param props Properties
 */
export const CalibrationIntervalSelect: React.FunctionComponent<CalibrationIntervalSelectProps> = (props) => {
    const {value, ...otherProps} = props

    let finalProps = Object.assign({
        value: value,
        native: true,
    }, otherProps)

    return (
        <Select {...finalProps} >
            <option value={CalibrationIntervalEnum.Day}>Days</option>
            <option value={CalibrationIntervalEnum.Month}>Months</option>
            <option value={CalibrationIntervalEnum.Year}>Years</option>
            {props.children}
        </Select>
    )
}