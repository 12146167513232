import { CalibrationLocationDto } from '../api/Ferrolog'
import NonResponsiveTableComponent from "../components/nonResponsiveTableComponent/NonResponsiveTableComponent"
import PageContainer from "../components/pageContainer/PageContainer"
import { EditMode } from '../components/EditMode'
import { calibrationLocationStore } from '../stores/CalibrationLocationStore'
import { useEffect } from 'react'
import CalibrationLocationTableRow from './CalibrationLocationTableRow'
import CalibrationLocationEdit from './CalibrationLocationEdit'
import TabFragment from '../components/tabComponent/TabFragment'

export default function CalibrationLocationTable() {
    const { fetch } = calibrationLocationStore()

    useEffect(() => {
        fetch()
    }, [])

    const columnHeaders =
    {
        showOnSmallScreen: [{ width: '20%', name: 'Name' }, { width: '20%', name: 'Contact Name' }, { width: '20%', name: 'Phone Number' }],
        showAlways: [{ width: '20%', name: 'Street' }, { width: '15%', name: 'City' }, { width: '5%', name: 'Country' }],
    }

    const renderRow = (item: CalibrationLocationDto) => <CalibrationLocationTableRow key={item.id} row={item} />

    return (
        <PageContainer
            header='Calibration Location'
            rootPage={
                <NonResponsiveTableComponent<CalibrationLocationDto>
                    store={calibrationLocationStore()}
                    columnHeaders={columnHeaders}
                    renderItem={renderRow}
                />
            }
            makeEditPage={(editMode: EditMode, selectedRowId?: number) =>
                <TabFragment
                    EditPage={<CalibrationLocationEdit editMode={editMode} calibrationLocationId={selectedRowId} />}
                    entityName={'CalibrationLocation'}
                    entityId={selectedRowId as number}
                    editMode={editMode}
                    CreatePage={<CalibrationLocationEdit editMode={EditMode.CreateNew} />}
                />}
            />
    )
}