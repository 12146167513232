import { useEffect } from "react"
import { InventoryLocationDto } from "../api/Ferrolog"
import { EditMode } from "../components/EditMode"
import NonResponsiveTableComponent from "../components/nonResponsiveTableComponent/NonResponsiveTableComponent"
import PageContainer from "../components/pageContainer/PageContainer"
import TabFragment from "../components/tabComponent/TabFragment"
import { inventoryLocationStore } from "../stores/InventoryLocationStore"
import InventoryLocationEdit from "./InventoryLocationEdit"
import InventoryLocationTableRow from "./InventoryLocationTableFragment"

export default function InventorylocationTable() {

    const { fetch } = inventoryLocationStore()

    useEffect(() => {
        fetch()
    }, [])

    const columnHeaders =
    {
        showOnSmallScreen: [{ width: '30%', name: 'Name' }, { width: '30%', name: 'Street' }, { width: '10%', name: 'Postcode' }],
        showAlways: [{ width: '20%', name: 'City' }, { width: '10%', name: 'Location' }],
    }

    const renderRow = (item: InventoryLocationDto) => <InventoryLocationTableRow key={item.id} row={item} />

    return (
        <PageContainer
            header='Inventory Locations'
            rootPage={
                <NonResponsiveTableComponent<InventoryLocationDto>
                    store={inventoryLocationStore()}
                    columnHeaders={columnHeaders}
                    renderItem={renderRow}
                />
            }
            makeEditPage={(editMode: EditMode, selectedRowId?: number) =>
                <TabFragment
                    EditPage={<InventoryLocationEdit editMode={editMode} inventoryLocationId={selectedRowId} />}
                    entityName={'InventoryLocation'}
                    entityId={selectedRowId as number}
                    editMode={editMode}
                    CreatePage={<InventoryLocationEdit editMode={EditMode.CreateNew} />}
                />}
        />

    )
}