import { Table, TableBody, TableCell, useMediaQuery, TableFooter, TableHead, TablePagination, tablePaginationClasses, TableRow, useTheme, TableContainer } from "@mui/material"
import { ReactElement, useEffect, useState } from "react"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"
import { ReadStore } from "../../stores/ReadStore"
import { pageContainerStore } from "../pageContainer/PageContainerStore"
import { Pagination } from "../../interface/PaginationInterface"

interface TableProps<T> {
    store: ReadStore<T>
    renderItem: (subItem: T) => JSX.Element
    columnHeaders: ColumnHeaders
    entityName? : string 
}

interface ColumnHeaders {
    showAlways: any[]
    showOnSmallScreen: any[]
}

const NonResponsiveTableComponent = <T extends unknown>(props: TableProps<T>): ReactElement => {
    const theme = useTheme()

    const showSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const showinAllSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

    const { selectedRowId , searchString } = pageContainerStore()

    const {
        columnHeaders,
        renderItem,
        store,
        entityName
    } = props

    const { Pagination: StorePagination, setPagination: setStorePagination, PaginationContainer, fetch } = store

    const [pagination, setPagination] = useState<Pagination>({
        CurrentPage: 1,
        PageSize: 10,
        searchString: ''
    })

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        setStorePagination(pagination)
    }, [pagination])

    useEffect(() => {
        fetch()
    }, [StorePagination])

    useEffect(() => {
        setPagination({...pagination, searchString: searchString})
    }, [searchString])

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        let pageChanage = newPage + 1

        setPagination({ ...pagination, CurrentPage: pageChanage })
        fetch()
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let rowsPerPageInteger = parseInt(event.target.value, 10)

        setPagination({ PageSize: rowsPerPageInteger, CurrentPage: 1, searchString: searchString })

        fetch()
    }
 

    return (
        selectedRowId !== 0 && showSmallScreen && entityName !== 'EquipmentStatus'
            ?
            <div></div>
            :
            <TableContainer aria-label="custom pagination table">
                <Table sx={showSmallScreen ? { marginTop: '15%' } : { marginTop: '0%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            {
                                columnHeaders.showOnSmallScreen.map((header) => (
                                    <TableCell key={header.name} width={header.width}>{header.name}</TableCell>
                                ))
                            }


                            {
                                showinAllSmallScreen
                                    ?
                                    columnHeaders.showAlways.map((header) => (
                                        <TableCell key={header.name} width={header.width}>{header.name}</TableCell>
                                    ))
                                    : null
                            }

                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ "& .MuiTableRow-root:hover": { backgroundColor: "#E0E0E0 " } }}>
                        {
                            PaginationContainer.items?.map((rows) => renderItem(rows))
                        }
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                sx={{
                                    border:0,
                                    [`& .${tablePaginationClasses.spacer}`]: {
                                        display: "none"
                                    },
                                    [`& .${tablePaginationClasses.toolbar}`]: {
                                        justifyContent: "center"
                                    }
                                }}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                count={PaginationContainer.totalItemCount ?? 0}
                                rowsPerPage={pagination.PageSize}
                                page={pagination.CurrentPage - 1}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            /></TableRow>

                    </TableFooter>
                </Table>
            </TableContainer >

    )
}
export default NonResponsiveTableComponent