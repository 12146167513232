/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type ActionResult = object;

export enum CalibrationIntervalEnum {
  Day = "Day",
  Month = "Month",
  Year = "Year",
}

export interface CalibrationLocation {
  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy: string;
  updatedBy: string;

  /** @format int32 */
  id?: number;
  tenantId?: string;
  name: string;
  street: string;
  city: string;

  /** @format int32 */
  countryId: number;
  country: CountryCode;
  postcode: string;
  contactName?: string;
  phoneNumber?: string;
  equipmentStatuses?: EquipmentStatus[];
}

export interface CalibrationLocationCreateDto {
  name: string;
  street: string;
  city: string;

  /** @format int32 */
  countryId?: number;
  postcode: string;
  contactName: string;
  phoneNumber: string;
}

export interface CalibrationLocationDto {
  name?: string;
  street?: string;
  city?: string;

  /** @format int32 */
  countryId?: number;
  postcode?: string;
  contactName?: string;
  phoneNumber?: string;

  /** @format int32 */
  id?: number;
}

export interface CalibrationLocationDtoPaginatedResponse {
  paginationFilter?: PaginationFilter;

  /** @format int32 */
  totalItemCount?: number;

  /** @format int32 */
  totalPageCount?: number;

  /** @format int32 */
  currentPageItemCount?: number;
  items?: CalibrationLocationDto[];
}

export interface CalibrationLocationUpdateDto {
  name?: string;
  street?: string;
  city?: string;

  /** @format int32 */
  countryId?: number;
  postcode?: string;
  contactName?: string;
  phoneNumber?: string;

  /** @format int32 */
  id?: number;
}

export enum CateogryEnum {
  Measurement = "Measurement",
  Validation = "Validation",
  Tool = "Tool",
}

export interface CommentCreateDto {
  comments?: string;

  /** @format int32 */
  entityId?: number;
  entityName?: string;
}

export interface CommentDto {
  comments?: string;

  /** @format int32 */
  entityId?: number;
  entityName?: string;

  /** @format int32 */
  id?: number;

  /** @format date-time */
  updatedAt?: string;
}

export interface CommentDtoPaginatedResponse {
  paginationFilter?: PaginationFilter;

  /** @format int32 */
  totalItemCount?: number;

  /** @format int32 */
  totalPageCount?: number;

  /** @format int32 */
  currentPageItemCount?: number;
  items?: CommentDto[];
}

export interface CommentUpdateDto {
  comments?: string;

  /** @format int32 */
  entityId?: number;
  entityName?: string;

  /** @format int32 */
  id?: number;
}

export interface CompanyCreateDto {
  name?: string;
  address?: string;
  zip?: string;
  city?: string;
  phoneNr?: string;
  web?: string;
  email?: string;
}

export interface CompanyDto {
  name: string;
  address?: string;
  zip?: string;
  city?: string;
  phoneNr?: string;
  web?: string;
  email?: string;

  /** @format int32 */
  id?: number;
}

export interface CompanyDtoPaginatedResponse {
  paginationFilter?: PaginationFilter;

  /** @format int32 */
  totalItemCount?: number;

  /** @format int32 */
  totalPageCount?: number;

  /** @format int32 */
  currentPageItemCount?: number;
  items?: CompanyDto[];
}

export interface CompanyUpdateDto {
  name?: string;
  address?: string;
  zip?: string;
  city?: string;
  phoneNr?: string;
  web?: string;
  email?: string;

  /** @format int32 */
  id?: number;
}

export interface CountryCode {
  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy: string;
  updatedBy: string;

  /** @format int32 */
  id?: number;
  tenantId?: string;
  alpha2: string;
}

export interface CountryCodeCreateDto {
  alpha2: string;
}

export interface CountryCodeDto {
  alpha2?: string;

  /** @format int32 */
  id?: number;
}

export interface CountryCodeDtoPaginatedResponse {
  paginationFilter?: PaginationFilter;

  /** @format int32 */
  totalItemCount?: number;

  /** @format int32 */
  totalPageCount?: number;

  /** @format int32 */
  currentPageItemCount?: number;
  items?: CountryCodeDto[];
}

export interface CountryCodeUpdateDto {
  alpha2?: string;

  /** @format int32 */
  id?: number;
}

export interface Equipment {
  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy: string;
  updatedBy: string;

  /** @format int32 */
  id?: number;
  tenantId?: string;
  equipmentId: string;
  description: string;
  locked?: boolean;
  lockedByUser?: string;

  /** @format date-time */
  lastCalibration?: string | null;
  hasValidCalibration?: boolean;
  cateogry?: CateogryEnum;
  inventoryLocation?: InventoryLocation;

  /** @format int32 */
  inventoryLocationId?: number | null;
  picture?: Files;

  /** @format int32 */
  pictureId?: number | null;
  manufacture?: string;
  manufactureDesignation?: string;
  storageInTheLocation?: string;
  producer?: string;
  equipmentStatuses?: EquipmentStatus[];

  /** @format int32 */
  calibrationInterval?: number;
  calibrationIntervalType?: CalibrationIntervalEnum;

  /** @format int32 */
  notificationInterval?: number;
  notificationIntervalType?: CalibrationIntervalEnum;
}

export interface EquipmentCreateDto {
  equipmentId: string;
  description: string;
  locked: boolean;
  lockedByUser: string;

  /** @format int32 */
  cateogry: number;
  producer?: string;

  /** @format int32 */
  inventoryLocationId?: number | null;
  manufacture?: string;
  manufactureDesignation?: string;
  storageInTheLocation?: string;

  /** @format int32 */
  pictureId?: number | null;

  /**
   * @format int32
   * @min 0
   */
  calibrationInterval?: number;
  calibrationIntervalType?: CalibrationIntervalEnum;

  /**
   * @format int32
   * @min 0
   */
  notificationInterval?: number;
  notificationIntervalType?: CalibrationIntervalEnum;
}

export interface EquipmentDto {
  equipmentId?: string;
  description?: string;
  locked?: boolean;
  lockedByUser?: string;

  /** @format int32 */
  cateogry?: number;
  producer?: string;

  /** @format int32 */
  inventoryLocationId?: number | null;
  manufacture?: string;
  manufactureDesignation?: string;
  storageInTheLocation?: string;

  /** @format int32 */
  pictureId?: number | null;

  /** @format int32 */
  calibrationInterval?: number;
  calibrationIntervalType?: CalibrationIntervalEnum;

  /** @format int32 */
  notificationInterval?: number;
  notificationIntervalType?: CalibrationIntervalEnum;

  /** @format int32 */
  id?: number;

  /** @format date-time */
  lastCalibration?: string | null;
  equipmentStatuses?: EquipmentStatusDto[];
}

export interface EquipmentDtoPaginatedResponse {
  paginationFilter?: PaginationFilter;

  /** @format int32 */
  totalItemCount?: number;

  /** @format int32 */
  totalPageCount?: number;

  /** @format int32 */
  currentPageItemCount?: number;
  items?: EquipmentDto[];
}

export interface EquipmentStatus {
  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy: string;
  updatedBy: string;

  /** @format int32 */
  id?: number;
  tenantId?: string;

  /** @format int32 */
  equipmentId?: number;
  equipment?: Equipment;

  /**
   * @format date
   * @example 2020-01-31
   */
  validFromDate?: string;
  status?: EquipmentStatusEnum;
  calibrationLocation?: CalibrationLocation;

  /** @format int32 */
  calibrationLocationId?: number | null;
  comment: string;
}

export interface EquipmentStatusCreateDto {
  /** @format int32 */
  equipmentId: number;

  /**
   * @format date
   * @example 2020-01-31
   */
  validFromDate: string;
  status: EquipmentStatusEnum;

  /** @format int32 */
  calibrationLocationId?: number | null;
  comment: string;
}

export interface EquipmentStatusDto {
  /** @format int32 */
  equipmentId?: number;

  /**
   * @format date
   * @example 2020-01-31
   */
  validFromDate?: string;
  status?: EquipmentStatusEnum;

  /** @format int32 */
  calibrationLocationId?: number | null;
  comment?: string;

  /** @format int32 */
  id?: number;
  updatedBy?: string;

  /** @format date-time */
  updatedAt?: string;
}

export interface EquipmentStatusDtoPaginatedResponse {
  paginationFilter?: PaginationFilter;

  /** @format int32 */
  totalItemCount?: number;

  /** @format int32 */
  totalPageCount?: number;

  /** @format int32 */
  currentPageItemCount?: number;
  items?: EquipmentStatusDto[];
}

export enum EquipmentStatusEnum {
  Calibrated = "Calibrated",
  CalibrationRequired = "CalibrationRequired",
  SentForCalibration = "SentForCalibration",
  Approved = "Approved",
  OnHold = "OnHold",
  Discarded = "Discarded",
  Purchased = "Purchased",
}

export interface EquipmentStatusUpdateDto {
  /** @format int32 */
  equipmentId?: number;

  /**
   * @format date
   * @example 2020-01-31
   */
  validFromDate?: string;
  status?: EquipmentStatusEnum;

  /** @format int32 */
  calibrationLocationId?: number | null;
  comment?: string;

  /** @format int32 */
  id?: number;
}

export interface EquipmentUpdateDto {
  equipmentId?: string;
  description?: string;
  locked?: boolean;
  lockedByUser?: string;

  /** @format int32 */
  cateogry?: number;
  producer?: string;

  /** @format int32 */
  inventoryLocationId?: number | null;
  manufacture?: string;
  manufactureDesignation?: string;
  storageInTheLocation?: string;

  /** @format int32 */
  pictureId?: number | null;

  /** @format int32 */
  calibrationInterval?: number;
  calibrationIntervalType?: CalibrationIntervalEnum;

  /** @format int32 */
  notificationInterval?: number;
  notificationIntervalType?: CalibrationIntervalEnum;

  /** @format int32 */
  id?: number;
}

export interface Files {
  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy: string;
  updatedBy: string;

  /** @format int32 */
  id?: number;
  tenantId?: string;
  fullFilePath: string;
  fileName: string;
  entityName: string;

  /** @format int32 */
  entityId: number;
  fileType: string;
  uploadByUser: string;

  /** @format date-time */
  uploadByDate: string;
  equipments?: Equipment[];
}

export interface FilesPaginatedResponse {
  paginationFilter?: PaginationFilter;

  /** @format int32 */
  totalItemCount?: number;

  /** @format int32 */
  totalPageCount?: number;

  /** @format int32 */
  currentPageItemCount?: number;
  items?: Files[];
}

export interface InventoryLocation {
  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy: string;
  updatedBy: string;

  /** @format int32 */
  id?: number;
  tenantId?: string;
  name: string;
  street: string;
  city: string;

  /** @format int32 */
  countryId: number;
  country: CountryCode;
  postcode: string;
  equipments?: Equipment[];
}

export interface InventoryLocationCreateDto {
  name?: string;
  street?: string;
  city?: string;

  /** @format int32 */
  countryId?: number;
  postcode?: string;
}

export interface InventoryLocationDto {
  name: string;
  street: string;
  city: string;

  /** @format int32 */
  countryId: number;
  postcode: string;

  /** @format int32 */
  id?: number;
}

export interface InventoryLocationDtoPaginatedResponse {
  paginationFilter?: PaginationFilter;

  /** @format int32 */
  totalItemCount?: number;

  /** @format int32 */
  totalPageCount?: number;

  /** @format int32 */
  currentPageItemCount?: number;
  items?: InventoryLocationDto[];
}

export interface InventoryLocationUpdateDto {
  name?: string;
  street?: string;
  city?: string;

  /** @format int32 */
  countryId?: number;
  postcode?: string;

  /** @format int32 */
  id?: number;
}

export interface PaginationFilter {
  /** @format int32 */
  currentPage?: number;

  /** @format int32 */
  pageSize?: number;
}

export interface Tenant {
  /** @format date-time */
  updatedAt?: string;

  /** @format date-time */
  createdAt?: string;
  createdBy: string;
  updatedBy: string;
  id?: string;
  name?: string;
  clientId?: string;
  subClientId?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title FerrologMaintenanceAPI, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags CalibrationLocations
     * @name CalibrationLocationsList
     * @request GET:/api/CalibrationLocations
     */
    calibrationLocationsList: (
      query?: { CurrentPage?: number; PageSize?: number; searchString?: string },
      params: RequestParams = {},
    ) =>
      this.request<CalibrationLocationDtoPaginatedResponse, any>({
        path: `/api/CalibrationLocations`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CalibrationLocations
     * @name CalibrationLocationsCreate
     * @request POST:/api/CalibrationLocations
     */
    calibrationLocationsCreate: (data: CalibrationLocationCreateDto, params: RequestParams = {}) =>
      this.request<CalibrationLocationDto, any>({
        path: `/api/CalibrationLocations`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CalibrationLocations
     * @name CalibrationLocationsDetail
     * @request GET:/api/CalibrationLocations/{id}
     */
    calibrationLocationsDetail: (id: number, params: RequestParams = {}) =>
      this.request<CalibrationLocationDto, any>({
        path: `/api/CalibrationLocations/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CalibrationLocations
     * @name CalibrationLocationsUpdate
     * @request PUT:/api/CalibrationLocations/{id}
     */
    calibrationLocationsUpdate: (id: number, data: CalibrationLocationUpdateDto, params: RequestParams = {}) =>
      this.request<CalibrationLocationDto, any>({
        path: `/api/CalibrationLocations/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CalibrationLocations
     * @name CalibrationLocationsDelete
     * @request DELETE:/api/CalibrationLocations/{id}
     */
    calibrationLocationsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/CalibrationLocations/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comments
     * @name CommentsList
     * @request GET:/api/Comments
     */
    commentsList: (
      query?: { entityName?: string; entityId?: number; CurrentPage?: number; PageSize?: number },
      params: RequestParams = {},
    ) =>
      this.request<CommentDtoPaginatedResponse, any>({
        path: `/api/Comments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comments
     * @name CommentsCreate
     * @request POST:/api/Comments
     */
    commentsCreate: (data: CommentCreateDto, params: RequestParams = {}) =>
      this.request<CommentDto, any>({
        path: `/api/Comments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comments
     * @name CommentsDetail
     * @request GET:/api/Comments/{id}
     */
    commentsDetail: (id: number, params: RequestParams = {}) =>
      this.request<CommentDto, any>({
        path: `/api/Comments/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comments
     * @name CommentsUpdate
     * @request PUT:/api/Comments/{id}
     */
    commentsUpdate: (id: number, data: CommentUpdateDto, params: RequestParams = {}) =>
      this.request<CommentDto, any>({
        path: `/api/Comments/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Comments
     * @name CommentsDelete
     * @request DELETE:/api/Comments/{id}
     */
    commentsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Comments/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyList
     * @request GET:/api/Company
     */
    companyList: (
      query?: { CurrentPage?: number; PageSize?: number; searchString?: string },
      params: RequestParams = {},
    ) =>
      this.request<CompanyDtoPaginatedResponse, any>({
        path: `/api/Company`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyCreate
     * @request POST:/api/Company
     */
    companyCreate: (data: CompanyCreateDto, params: RequestParams = {}) =>
      this.request<CompanyDto, any>({
        path: `/api/Company`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyDetail
     * @request GET:/api/Company/{id}
     */
    companyDetail: (id: number, params: RequestParams = {}) =>
      this.request<CompanyDto, any>({
        path: `/api/Company/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyUpdate
     * @request PUT:/api/Company/{id}
     */
    companyUpdate: (id: number, data: CompanyUpdateDto, params: RequestParams = {}) =>
      this.request<CompanyDto, any>({
        path: `/api/Company/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Company
     * @name CompanyDelete
     * @request DELETE:/api/Company/{id}
     */
    companyDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Company/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryCodes
     * @name CountryCodesList
     * @request GET:/api/CountryCodes
     */
    countryCodesList: (
      query?: { CurrentPage?: number; PageSize?: number; searchString?: string },
      params: RequestParams = {},
    ) =>
      this.request<CountryCodeDtoPaginatedResponse, any>({
        path: `/api/CountryCodes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryCodes
     * @name CountryCodesCreate
     * @request POST:/api/CountryCodes
     */
    countryCodesCreate: (data: CountryCodeCreateDto, params: RequestParams = {}) =>
      this.request<CountryCodeDto, any>({
        path: `/api/CountryCodes`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryCodes
     * @name CountryCodesDetail
     * @request GET:/api/CountryCodes/{id}
     */
    countryCodesDetail: (id: number, params: RequestParams = {}) =>
      this.request<CountryCodeDto, any>({
        path: `/api/CountryCodes/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryCodes
     * @name CountryCodesUpdate
     * @request PUT:/api/CountryCodes/{id}
     */
    countryCodesUpdate: (id: number, data: CountryCodeUpdateDto, params: RequestParams = {}) =>
      this.request<CountryCodeDto, any>({
        path: `/api/CountryCodes/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CountryCodes
     * @name CountryCodesDelete
     * @request DELETE:/api/CountryCodes/{id}
     */
    countryCodesDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/CountryCodes/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipments
     * @name EquipmentsList
     * @request GET:/api/Equipments
     */
    equipmentsList: (
      query?: { CurrentPage?: number; PageSize?: number; searchString?: string },
      params: RequestParams = {},
    ) =>
      this.request<EquipmentDtoPaginatedResponse, any>({
        path: `/api/Equipments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipments
     * @name EquipmentsCreate
     * @request POST:/api/Equipments
     */
    equipmentsCreate: (data: EquipmentCreateDto, params: RequestParams = {}) =>
      this.request<EquipmentDto, any>({
        path: `/api/Equipments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipments
     * @name EquipmentsDetail
     * @request GET:/api/Equipments/{id}
     */
    equipmentsDetail: (id: number, params: RequestParams = {}) =>
      this.request<EquipmentDto, any>({
        path: `/api/Equipments/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipments
     * @name EquipmentsUpdate
     * @request PUT:/api/Equipments/{id}
     */
    equipmentsUpdate: (id: number, data: EquipmentUpdateDto, params: RequestParams = {}) =>
      this.request<EquipmentDto, any>({
        path: `/api/Equipments/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Equipments
     * @name EquipmentsDelete
     * @request DELETE:/api/Equipments/{id}
     */
    equipmentsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Equipments/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquipmentStatuses
     * @name EquipmentStatusesList
     * @request GET:/api/EquipmentStatuses
     */
    equipmentStatusesList: (
      query?: { equipmentId?: number; CurrentPage?: number; PageSize?: number },
      params: RequestParams = {},
    ) =>
      this.request<EquipmentStatusDtoPaginatedResponse, any>({
        path: `/api/EquipmentStatuses`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquipmentStatuses
     * @name EquipmentStatusesCreate
     * @request POST:/api/EquipmentStatuses
     */
    equipmentStatusesCreate: (data: EquipmentStatusCreateDto, params: RequestParams = {}) =>
      this.request<EquipmentStatusDto, any>({
        path: `/api/EquipmentStatuses`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquipmentStatuses
     * @name EquipmentStatusesDetail
     * @request GET:/api/EquipmentStatuses/{id}
     */
    equipmentStatusesDetail: (id: number, params: RequestParams = {}) =>
      this.request<EquipmentStatusDto, any>({
        path: `/api/EquipmentStatuses/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquipmentStatuses
     * @name EquipmentStatusesUpdate
     * @request PUT:/api/EquipmentStatuses/{id}
     */
    equipmentStatusesUpdate: (id: number, data: EquipmentStatusUpdateDto, params: RequestParams = {}) =>
      this.request<EquipmentStatusDto, any>({
        path: `/api/EquipmentStatuses/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquipmentStatuses
     * @name EquipmentStatusesDelete
     * @request DELETE:/api/EquipmentStatuses/{id}
     */
    equipmentStatusesDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/EquipmentStatuses/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesList
     * @request GET:/api/Files
     */
    filesList: (query?: { CurrentPage?: number; PageSize?: number }, params: RequestParams = {}) =>
      this.request<FilesPaginatedResponse, any>({
        path: `/api/Files`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesDetail
     * @request GET:/api/Files/{id}
     */
    filesDetail: (id: number, params: RequestParams = {}) =>
      this.request<File, ActionResult>({
        path: `/api/Files/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesDelete
     * @request DELETE:/api/Files/{id}
     */
    filesDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Files/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesDetail2
     * @request GET:/api/Files/{entityName}/{entityId}
     * @originalName filesDetail
     * @duplicate
     */
    filesDetail2: (
      entityName: string,
      entityId: number,
      query?: { CurrentPage?: number; PageSize?: number },
      params: RequestParams = {},
    ) =>
      this.request<FilesPaginatedResponse, any>({
        path: `/api/Files/${entityName}/${entityId}`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesCreate
     * @request POST:/api/Files/{entityName}/{entityId}
     */
    filesCreate: (
      entityName: string,
      entityId: number,
      data: { file?: File; fileType?: string; uploadByUser?: string; uploadByDate?: string },
      params: RequestParams = {},
    ) =>
      this.request<Files, any>({
        path: `/api/Files/${entityName}/${entityId}`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Files
     * @name FilesStreamDetail
     * @request GET:/api/Files/{id}/stream
     */
    filesStreamDetail: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Files/${id}/stream`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryLocation
     * @name InventoryLocationList
     * @request GET:/api/InventoryLocation
     */
    inventoryLocationList: (
      query?: { CurrentPage?: number; PageSize?: number; searchString?: string },
      params: RequestParams = {},
    ) =>
      this.request<InventoryLocationDtoPaginatedResponse, any>({
        path: `/api/InventoryLocation`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryLocation
     * @name InventoryLocationCreate
     * @request POST:/api/InventoryLocation
     */
    inventoryLocationCreate: (data: InventoryLocationCreateDto, params: RequestParams = {}) =>
      this.request<InventoryLocationDto, any>({
        path: `/api/InventoryLocation`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryLocation
     * @name InventoryLocationDetail
     * @request GET:/api/InventoryLocation/{id}
     */
    inventoryLocationDetail: (id: number, params: RequestParams = {}) =>
      this.request<InventoryLocationDto, any>({
        path: `/api/InventoryLocation/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryLocation
     * @name InventoryLocationUpdate
     * @request PUT:/api/InventoryLocation/{id}
     */
    inventoryLocationUpdate: (id: number, data: InventoryLocationUpdateDto, params: RequestParams = {}) =>
      this.request<InventoryLocationDto, any>({
        path: `/api/InventoryLocation/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags InventoryLocation
     * @name InventoryLocationDelete
     * @request DELETE:/api/InventoryLocation/{id}
     */
    inventoryLocationDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/InventoryLocation/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pdf
     * @name GetApi
     * @request GET:/api/Pdf
     */
    getApi: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Pdf`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsList
     * @request GET:/api/Tenants
     */
    tenantsList: (query?: { CurrentPage?: number; PageSize?: number }, params: RequestParams = {}) =>
      this.request<Tenant[], any>({
        path: `/api/Tenants`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsCreate
     * @request POST:/api/Tenants
     */
    tenantsCreate: (data: Tenant, params: RequestParams = {}) =>
      this.request<Tenant, any>({
        path: `/api/Tenants`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsDetail
     * @request GET:/api/Tenants/{id}
     */
    tenantsDetail: (id: string, params: RequestParams = {}) =>
      this.request<Tenant, any>({
        path: `/api/Tenants/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsUpdate
     * @request PUT:/api/Tenants/{id}
     */
    tenantsUpdate: (id: string, data: Tenant, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Tenants/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tenants
     * @name TenantsDelete
     * @request DELETE:/api/Tenants/{id}
     */
    tenantsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Tenants/${id}`,
        method: "DELETE",
        ...params,
      }),
  };
}
