import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material"
import { pageContainerStore } from "../components/pageContainer/PageContainerStore"
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'

type ButtonProps = {
    createOrUpdateItem: () => void
    entityName?: string
}

export const CreateCancelButton: React.FunctionComponent<ButtonProps> = (props) => {

    const { createOrUpdateItem, entityName } = props
    const { startRemovingPage, setSelectedRowId } = pageContainerStore()

    const theme = useTheme()

    const showSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const cancelButton = () => {
        startRemovingPage()
        setSelectedRowId(0)
    }

    return (
        <>
            <Grid item xs={entityName === 'Equipment' ? 6 : 12} md={entityName === 'Equipment' ? 3 : 6}>
                <div style={showSmallScreen ? { marginTop: '10%' } : { padding: 10 }}>
                    <IconButton color="error" onClick={() => cancelButton()}>
                        <ClearIcon fontSize="large" />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={entityName === 'Equipment' ? 6 : 12} md={entityName === 'Equipment' ? 3 : 6}>
                <div style={showSmallScreen ? { marginTop: '10%' } : { padding: 10 }}>
                    <IconButton color='success' onClick={() => createOrUpdateItem()}>
                        <CheckIcon fontSize="large" />
                    </IconButton>
                </div>
            </Grid>
        </>
    )

}