import { Files, FilesPaginatedResponse } from '../api/Ferrolog'
import TableComponent from '../components/table/TableComponent'
import FileTableRow from "./components/FileTableRowFragments"
import { Pagination } from "../interface/PaginationInterface"

export default function FileTable(props: { pagination: Pagination, fetchFile: () => void, getPaginationContainer: FilesPaginatedResponse }) {
    const { pagination, fetchFile, getPaginationContainer } = props

    let columnHeaders = {
        showAlways: [''],
        showOnMediumScreen: ['Upload Date'],
        showOnSmallScreen: ['Upload by User'],
        showOnAllScreen: ['Name']
    }

    const renderRow = (item: Files) => <FileTableRow row={item} key={item.id} fetch={fetchFile } />

    return (
            <TableComponent<Files>
            paginationContainer={getPaginationContainer}
            fetch={fetchFile}
            Pagination={pagination}
                navigationRoutName={''}
                columnHeaders={columnHeaders}
                renderItem={renderRow}
            />
    )
}