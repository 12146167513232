import { Button, IconButton, List, ListItem, ListItemText, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import ClearIcon from '@mui/icons-material/Clear'
import CheckIcon from '@mui/icons-material/Check'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { CommentDto, CommentDtoPaginatedResponse } from "../api/Ferrolog"
import DeleteIcon from '@mui/icons-material/Delete'
import moment from "moment"
import { ferrologApi } from "../App"

export default function Comment(props: { entityId: number, entityName: string }) {
    const theme = useTheme()
    const env = window.navigator.language

    const [CommentList, SetCommentList] = useState<CommentDtoPaginatedResponse>({
        currentPageItemCount: 0,
        items: [],
        paginationFilter: { currentPage: 1, pageSize: 10 },
        totalItemCount: 0,
        totalPageCount: 1,
    })
    const [defaultCommentString, setDefaultCommentString] = useState('')

    const showSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const { entityId, entityName } = props

    const [commentCreate, setCommentCreate] = useState<CommentDto>({
        comments: '',
        entityId: entityId,
        entityName: entityName,
        id: 0
    })

    const [editMode, setEditMode] = useState(false);
    const [rowid, setrowId] = useState(0)

    useEffect(() => {
         setCommentCreate({ ...commentCreate, entityId: entityId, entityName: entityName })
        fetchComment()
    }, [entityId, entityName])

    const fetchComment = () => ferrologApi.commentsList({ entityName: entityName, entityId: entityId as number }).then(response => SetCommentList(response.data))

    const addComment = () => ferrologApi.commentsCreate(commentCreate).then(() => {
        setDefaultCommentString('')
        fetchComment()
    })

    const updateComment = (id: number) => {
        setrowId(id)
        setEditMode(true)
    }

    const deleteComment = (id: number) => ferrologApi.commentsDelete(id).then(() => fetchComment())

    const editComment = (id: number) => {
        let updateComment = { ...commentCreate, id: id }
        ferrologApi.commentsUpdate(id as number, updateComment).then(() => {
            setEditMode(false)
            fetchComment()
        })
    }

    const handleComment = (comment: string) => {
        setDefaultCommentString(comment)
        setCommentCreate({ ...commentCreate, comments: comment })
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                <TextField
                    fullWidth
                    placeholder="Comments"
                    multiline
                    rows={2}
                    maxRows={5}
                    value={defaultCommentString}
                    onChange={c => handleComment(c.target.value)}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
                <Button variant="contained" color="success" onClick={() => addComment()}>
                    Add Comment
                </Button>
            </div>
            <List sx={{ width: '100%' }}>
                {CommentList.items?.map((row) => (
                    <ListItem
                        key={row.id}
                        secondaryAction={
                            editMode && row.id === rowid
                                ?
                                <div style={{ display: 'flex', flexDirection: 'row', marginRight: showSmallScreen ?'0px':'30px' } }>
                                    <IconButton color='success' onClick={() => editComment(row.id as number)}>
                                        <CheckIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton color="error" onClick={() => setEditMode(false)}>
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </div>
                                :
                                <div style={{ marginTop: '20px', marginRight: '20px' }}>
                                    <IconButton aria-label="comment" onClick={() => updateComment(row.id as number)}>
                                        <ModeEditIcon />
                                    </IconButton>
                                    <IconButton aria-label="comment" onClick={() => deleteComment(row.id as number)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                        }>
                        <ListItemText>
                            <div style={{ flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography component={'span'} variant="subtitle1" color='primary'>UserName</Typography>
                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                        <Typography component={'span'} variant="subtitle1" color='primary'>{moment(row.updatedAt).locale(env).format('L HH:MM')}</Typography>
                                    </div>
                                </div>
                                {
                                    editMode && row.id === rowid
                                        ?
                                        <>
                                            <TextField style={{ width: '90%', paddingBottom: '10px' }}
                                                multiline
                                                inputProps={{ maxLength: 1000 }}
                                                rows={2}
                                                defaultValue={row.comments}
                                                onChange={c => setCommentCreate({ ...commentCreate, comments: c.target.value })}
                                            />

                                        </>
                                        :
                                        <Typography component={'span'} variant="subtitle2" style={{ marginTop: 5 }} color='primary'>{row.comments}</Typography>
                                }
                                <div style={{ background: '#ced4da', height: 0.5 }}
                                />
                            </div>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    )
}