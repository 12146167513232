import { Avatar, Button, Grid, IconButton, Select, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ReactElement, useEffect, useState } from "react"
import { CalibrationIntervalEnum, EquipmentCreateDto, InventoryLocationCreateDto } from "../api/Ferrolog"
import { EditMode } from "../components/EditMode"
import { pageContainerStore } from "../components/pageContainer/PageContainerStore"
import { equipmentStore } from "../stores/EquipmentStore"
import { Cateogries } from "../constants/Cateogries"
import { inventoryLocationStore } from "../stores/InventoryLocationStore"
import { countryCodesStore } from "../stores/CountryCodeStore"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNavigate } from "react-router-dom"
import { routes } from "../Routes"
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { fileStore } from "../stores/FileStore"
import moment from "moment"
import EquipmentStatusTable from "../EquipmentStatus/EquipmentStatusTable"
import EquipmentStatusEdit from "../EquipmentStatus/EquipmentStatusEdit"
import { equipmentStatusStore } from "../stores/EquipmentStatusStore"
import { CalibrationIntervalSelect } from "../controls/CalibrationIntervalSelect"
import { calculateNextCalibration, calculateNextNotification, hasValidCalibration, showCalibrationNotification } from "./EquipmentHelpers"
import { getLatestActiveStatus } from "../EquipmentStatus/EquipmentStatusHelpers"
import { CreateCancelButton } from "../controls/CreateCancelButton"
import { DateTextField } from "../controls/DateTextField"


interface EquipmentEditProps {
    editMode: EditMode
    equipmentId?: number
}

export default function EquipmentEdit(props: EquipmentEditProps): ReactElement {
    const { editMode, equipmentId } = props
    const { startRemovingPage, addPage } = pageContainerStore()
    const { fileRequiredField, displayPicture, createFile, deleteFile } = fileStore()

    const theme = useTheme()

    const showSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const navigate = useNavigate()

    const emptyInventoryLocation = {
        name: '',
        street: '',
        city: '',
        countryId: 0,
        postcode: '',
    }

    const { create, updateEquipment, getEmptyEquipment, getSelectedEquipment } = equipmentStore()

    const { fetch: fetchInventoryLocations, fetchById, PaginationContainer: inventoryLocations } = inventoryLocationStore()
    const { readableStatus } = equipmentStatusStore()

    const { getCountryCodeAlpha2ById } = countryCodesStore()

    const [filePath, setFilePath] = useState<any>('')

    const selectedEquipment = getSelectedEquipment(equipmentId)

    const [equipment, setEquipment] = useState<EquipmentCreateDto>(equipmentId ? { ...(getSelectedEquipment(equipmentId) ?? getEmptyEquipment()) as EquipmentCreateDto } : getEmptyEquipment())

    const [inventoryLocation, setInventoryLocation] = useState<InventoryLocationCreateDto>({
        name: '',
        street: '',
        city: '',
        countryId: 0,
        postcode: '',
    })

    const CateogryDropDown = [{ id: 0, name: 'select any one' }, { id: 1, name: Cateogries.MEASUREMENT }, { id: 2, name: Cateogries.VALIDATION }, { id: 3, name: Cateogries.TOOL }]

    useEffect(() => {
        if (equipmentId) {
            setEquipment((getSelectedEquipment(equipmentId) as EquipmentCreateDto) ?? getEmptyEquipment())

            fetchById(equipment.inventoryLocationId as number).then(detail => {
                setInventoryLocation({ ...detail })
            })
            displayPicture(equipment.pictureId as number).then(response => setFilePath(response)).catch(error => setFilePath(''))

        }
        else {
            setEquipment(getEmptyEquipment())
            setInventoryLocation(emptyInventoryLocation)
            setFilePath('')
        }

        fetchInventoryLocations()
    }, [equipmentId, equipment.pictureId])

    useEffect(() => {
        if (equipment.inventoryLocationId)
            fetchById(equipment.inventoryLocationId as number).then(detail => setInventoryLocation({ ...detail }))
    }, [equipment.inventoryLocationId])

    const createOrUpdateEquipmentChanges = () => {
        if (editMode === EditMode.EditExisting && equipmentId !== null) {
            let Updateequipment = { ...equipment, id: equipmentId as number }

            updateEquipment(equipmentId as number, Updateequipment)
        }
        else {
            create(equipment)

            startRemovingPage()
        }
    }

    const handleFileChange = (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement
        const file: File = (target.files as FileList)[0]

        saveUpdateFile(file)
    }

    const copyAddress = () => {
        if (equipmentId !== null)
            navigator.clipboard.writeText(`${inventoryLocation.name},${inventoryLocation.street},${getCountryCodeAlpha2ById(inventoryLocation.countryId as number)}-${inventoryLocation.postcode}`)
    }

    const latestStatus = getLatestActiveStatus(selectedEquipment)

    const saveUpdateFile = (file: File) => {
        fileRequiredField.entityId = equipmentId as number
        fileRequiredField.entityName = 'Equipment'
        fileRequiredField.fileType = 'photodataset'
        fileRequiredField.uploadByDate = moment().format('YYYY-MM-DD HH:mm:ss')
        fileRequiredField.uploadByUser = 'TestString'

        let UpdatePictureId = { ...equipment, pictureId: null, id: equipmentId, }
        updateEquipment(equipmentId as number, UpdatePictureId).then(resposne => {
            deleteFile(equipment.pictureId as number).then().catch()
        })

        createFile(file).then(response => {
            let UpdatePictureId = { ...equipment, pictureId: response as number, id: equipmentId, }
            updateEquipment(equipmentId as number, UpdatePictureId)

            if (equipmentId) {
                displayPicture(response as number).then(response => {
                    setFilePath(response)
                })
            }
            else
                setFilePath('')
        })
    }

    const statusEditPage = () =>   addPage(<EquipmentStatusEdit editMode={EditMode.CreateNew} equipmentId={equipmentId!} />)

    const nextCalibration = calculateNextCalibration({...equipment, lastCalibration: selectedEquipment?.lastCalibration})
    const nextNotificaiton = calculateNextNotification({...equipment, lastCalibration: selectedEquipment?.lastCalibration})

    return (
        <Grid container>
            <CreateCancelButton createOrUpdateItem={createOrUpdateEquipmentChanges} entityName={'Equipment'!} />
            <Grid item xs={10} md={6}>
                <div style={{ padding: 10 }}>
                    <Button fullWidth variant="contained" color='success'
                        disabled={!equipmentId}
                        onClick={() => statusEditPage()}>
                        Change Status
                    </Button>
                </div>
            </Grid>
            <Grid item xs={5} md={1}>
                <div style={{ padding: 10, marginTop: 30 }}>
                    <div style={{ backgroundColor: hasValidCalibration(selectedEquipment) ? 'green' : 'red', height: 30, width: 30, borderRadius: '50%', marginTop: 10, marginLeft: 10 }}>
                    </div>
                </div>
            </Grid>
            <Grid item xs={5} md={1}>
                <div style={{ padding: 10, marginTop: 40 }}>
                    {showCalibrationNotification(selectedEquipment) &&
                    <Avatar sx={{ bgcolor: 'transparent' }} variant="square" src={window.location.origin + "/traingle.png"} style={{ height: 30, width: 30 }}>
                    </Avatar>}
                </div>
            </Grid>
            <Grid item xs={6} md={4}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Status Name</Typography>
                    <TextField
                        fullWidth
                        id="equipmentId"
                        disabled={true}
                        value={latestStatus ? readableStatus(latestStatus.status!) : ''}
                    />
                </div>
            </Grid>
            <Grid item xs={6} md={4}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Next Calibration</Typography>
                    <DateTextField
                        fullWidth
                        id="nextCalibration"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                        value={nextCalibration}
                    />
                </div>
            </Grid>
            <Grid item xs={6} md={2}>
                <div style={{ padding: 10, marginTop: 20 }}>
                    <Button fullWidth variant="contained" color='success'>
                        Info Button
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Equipment Id</Typography>
                    <TextField
                        fullWidth
                        id="equipmentId"
                        value={equipment.equipmentId}
                        onChange={e => setEquipment({ ...equipment, equipmentId: e.target.value })}
                    />
                </div>
            </Grid>

            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Description</Typography>
                    <TextField
                        fullWidth
                        id="description"
                        multiline
                        maxRows={4}
                        value={equipment.description}
                        onChange={e => setEquipment({ ...equipment, description: e.target.value })}
                    />
                </div>
            </Grid>

            <Grid item xs={showSmallScreen ? 12 : 6} md={showSmallScreen ? 6 : 3}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Cateogry</Typography>
                    <Select native
                        fullWidth value={equipment.cateogry}
                        onChange={e => setEquipment({ ...equipment, cateogry: Number(e.target.value) })}>
                        {CateogryDropDown.map((option) => (
                            <option key={'category-' + option.id} value={option.id}>{option.name}</option>
                        ))}
                    </Select>
                </div>
            </Grid>

            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 4}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Hersteller</Typography>
                    <TextField
                        fullWidth
                        id="Manufacture"
                        value={equipment.manufacture}
                        onChange={e => setEquipment({ ...equipment, manufacture: e.target.value })}
                    />
                </div>
            </Grid>

            <Grid item xs={showSmallScreen ? 12 : 10} sm={10} md={showSmallScreen ? 6 : 5}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Hersteller Bezeichung</Typography>
                    <TextField
                        fullWidth
                        id="ManufactureDesignation"
                        value={equipment.manufactureDesignation}
                        onChange={e => setEquipment({ ...equipment, manufactureDesignation: e.target.value })}
                    />
                </div>
            </Grid>

            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Location</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Select native fullWidth
                            value={equipment.inventoryLocationId}
                            id="inventory-location"
                            onChange={e => setEquipment({ ...equipment, inventoryLocationId: Number(e.target.value) })}
                        >
                            <option key='il-0' value={0}>please select</option>
                            {inventoryLocations.items?.map(il => <option key={'il-' + il.id} value={il.id}>{il.name}</option>)}
                        </Select>
                        <IconButton color="primary" onClick={() => copyAddress()}>
                            <ContentCopyIcon fontSize="small"
                                sx={{
                                    ml: 1,
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: "transparent"
                                    }
                                }} />
                        </IconButton>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Langerug in the standort</Typography>
                    <TextField
                        fullWidth
                        id="storageinthelocation"
                        value={equipment.storageInTheLocation}
                        onChange={e => setEquipment({ ...equipment, storageInTheLocation: e.target.value })}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10, paddingBottom: 0 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Street</Typography>
                    <TextField
                        fullWidth
                        id="street"
                        value={inventoryLocation.street}
                        disabled={true}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <input type="file" name="uploadPicture" accept="image/png, image/jpeg" id="uploadPicture" style={{ display: 'none' }} onChange={handleFileChange} />
                    <label htmlFor={editMode === EditMode.CreateNew ? '' : 'uploadPicture'}>
                        <IconButton
                            component='span'
                            style={{ marginTop: editMode === EditMode.CreateNew ? '20px' :'30px'}}
                            sx={{ height: 100, width: 100 }}
                            disabled={editMode === EditMode.CreateNew && true}>
                            <AttachFileIcon fontSize='large' />
                        </IconButton>
                    </label>
                    {
                        filePath !== '' &&
                        <IconButton
                            name="uploadPicture"
                            onClick={() => navigate(routes.zoomPage, { state: filePath })}
                                sx={{
                                height: 150,
                                width: 150,
                                justifyContent: 'center'
                            }}>
                            <img
                                src={filePath}
                                style={{ width: 150, height: 150 }}
                                alt="" />
                        </IconButton>
                    }

                </div>
            </Grid>

            <Grid item xs={12} sm={6}>
                <div style={{ padding: 5, marginTop: editMode === EditMode.CreateNew ? -30 : -10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>City</Typography>
                    <TextField
                        fullWidth
                        id="city"
                        value={inventoryLocation.city}
                        disabled={true}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
            </Grid>


            <Grid item xs={12} sm={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Country</Typography>
                    <div style={{ flexDirection: 'row' }}>
                        <TextField
                            fullWidth
                            id="Country"
                            value={getCountryCodeAlpha2ById(inventoryLocation.countryId as number)}
                            disabled={true}
                        />
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Post Code</Typography>
                    <div style={{ flexDirection: 'row' }}>
                        <TextField
                            fullWidth
                            id="PostCode"
                            value={inventoryLocation.postcode}
                            disabled={true}
                        />
                    </div>
                </div>
            </Grid>


            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 4}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Last Calibration</Typography>
                    <DateTextField
                        fullWidth
                        id="lastCalibration"
                        value={selectedEquipment?.lastCalibration}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={true}
                    />
                </div>
            </Grid>
            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 2}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Duration</Typography>
                    <TextField
                        fullWidth
                        type="number"
                        value={equipment.calibrationInterval}
                        onChange={e => setEquipment({ ...equipment, calibrationInterval: Number(e.target.value) })}
                        InputProps={{
                            inputProps: {
                                min: 1
                            }
                        }}
                    />
                </div>
            </Grid>
            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 2}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Cycle</Typography>
                    <CalibrationIntervalSelect
                        fullWidth
                        value={equipment.calibrationIntervalType}
                        onChange={e => setEquipment({ ...equipment, calibrationIntervalType: e.target.value as CalibrationIntervalEnum })}
                    />
                </div>
            </Grid>
            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 4}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Next Calibration</Typography>
                    <DateTextField
                        fullWidth
                        disabled={true}
                        value={nextCalibration}
                    />
                </div>
            </Grid>

            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 4}>
                <Typography variant="subtitle1" component={'span'} style={{ padding: 10, fontWeight: 'bold' }} color='primary'>Notification</Typography>
            </Grid>
            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 2}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Duration</Typography>
                    <TextField
                        fullWidth
                        type="number"
                        value={equipment.notificationInterval}
                        onChange={e => setEquipment({ ...equipment, notificationInterval: Number(e.target.value) })}
                        InputProps={{
                            inputProps: {
                                min: 1
                            }
                        }}
                    />
                </div>
            </Grid>
            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 2}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Cycle</Typography>
                    <CalibrationIntervalSelect
                        fullWidth
                        value={equipment.notificationIntervalType}
                        onChange={e => setEquipment({ ...equipment, notificationIntervalType: e.target.value as CalibrationIntervalEnum })}
                    />
                </div>
            </Grid>
            <Grid item xs={showSmallScreen ? 12 : 8} md={showSmallScreen ? 6 : 4}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Notification Date</Typography>
                    <DateTextField
                        fullWidth
                        disabled={true}
                        value={nextNotificaiton}
                    />
                </div>
            </Grid>

            {equipmentId &&
                <Grid item xs={12}>
                    <EquipmentStatusTable />
                </Grid>
            }
        </Grid>
    )
}
