import './App.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { ThemeProvider } from '@emotion/react'
import ColorFontTheme from './theme/color'

import { Routes, Route, Link } from 'react-router-dom'
import { externalRoutes, routes } from './Routes'
import EquipmentTable from './equipment/EquipmentTable'
import CompanyTable from './company/CompanyTable'
import InventorylocationTable from './inventoryLocation/InventoryLocationTable'
import { countryCodesStore } from './stores/CountryCodeStore'
import { useEffect } from 'react'
import ZoomPage from './zoomPage/ZoomPage'
import CalibrationLocationTable from './calibrationLocation/CalibrationLocationTable'
import { Api } from './api/Ferrolog'

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());
const token = params.token ?? ''

export const ferrologApi = new Api({ baseApiParams: { headers: { 'Authorization': 'Bearer ' + token } } }).api

function App() {
    const { fetch } = countryCodesStore()

    useEffect(() => {
        fetch()
    }, [fetch])

    const appNavigation = () => <ThemeProvider theme={ColorFontTheme}>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path={routes.home} element={<Home />} />

            <Route path={routes.equipments} element={<EquipmentTable />} />

            <Route path={routes.companies} element={<CompanyTable />} />

            <Route path={routes.inventoryLocations} element={<InventorylocationTable />} />

            <Route path={routes.zoomPage} element={<ZoomPage />} />

            <Route path={routes.calibrationLocations} element={<CalibrationLocationTable />} />

        </Routes>
    </ThemeProvider>

    const noTokenWarning = () => <>
        <main style={{marginLeft: 20}}>
            <h2>No token supplied!</h2>
            <p>Pass it as query parameter in the form ?token=&lt;your-user-token&gt;</p>
            <a href='?token=development-only-jwt-placeholder'>E.g like this dev setup</a>
        </main>
    </>

    if (token !== '')
        return appNavigation()
    else
        return noTokenWarning()
}

function Home() {
    window.addEventListener("popstate", () => refreshPage())

    function refreshPage() {
        setTimeout(() => {
            window.location.reload()
        }, 0)
    }

    const queryToken = '?token=' + token

    return (
        <>
            <main style={{marginLeft: 20}}>
                <h2>Welcome to the test index!</h2>
                <p>Pick any link to go there.</p>
            </main>
            <ul>
                <li><Link to={routes.companies + queryToken}>Company Table</Link></li>
                <li><Link to={routes.calibrationLocations + queryToken}>Calibration Location Table</Link></li>
                <li><Link to={routes.equipments + queryToken}>Equipment table</Link></li>
                <li><Link to={routes.inventoryLocations + queryToken}>Inventorylocation Table</Link></li>
                <li><a href={externalRoutes.swagger} rel="noreferrer">Swagger</a></li>
            </ul>
        </>
    )
}

export default App;
