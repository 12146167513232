import { createTheme } from '@mui/material/styles';

const ColorFontTheme = createTheme({
   
    palette: {
      
        primary:
        {
            main: '#000000',

        },
    },
    typography: {
       
        subtitle1: {
            fontSize: 15,
        },
        body1: {
            fontWeight: 500,
        }
    }

});

export default ColorFontTheme;