import { EquipmentDto, EquipmentStatusDto, EquipmentStatusEnum } from "../api/Ferrolog"

const now = new Date()

export const getLatestActiveStatus = (equipment?: EquipmentDto, requiredStatus?: EquipmentStatusEnum): EquipmentStatusDto | undefined => {
    if (!equipment ||
        (equipment.equipmentStatuses?.length ?? 0) < 1)
        return undefined

    let equipmentStatuses = equipment.equipmentStatuses ?? []

    return getLatestActiveStatusFromList(equipmentStatuses, requiredStatus)
}

export const getLatestActiveStatusFromList = (equipmentStatuses: EquipmentStatusDto[], requiredStatus?: EquipmentStatusEnum): EquipmentStatusDto | undefined => {
    if(requiredStatus)
        equipmentStatuses = equipmentStatuses.filter(es => es.status === requiredStatus)
    
    let pastStatuses = equipmentStatuses.filter(es => (new Date(es.validFromDate ?? '')) <= now)
    pastStatuses?.sort((a, b) => (new Date(a.validFromDate ?? '') >= new Date(b.validFromDate ?? '')) ? -1 : 1)

    if (!pastStatuses ||
        pastStatuses.length < 1)
        return undefined

    return pastStatuses[0]
}

export const getLatestStatus = (equipment?: EquipmentDto, requiredStatus?: EquipmentStatusEnum): EquipmentStatusDto | undefined => {
    if (!equipment ||
        (equipment.equipmentStatuses?.length ?? 0) < 1)
        return undefined

    let equipmentStatuses = equipment.equipmentStatuses ?? []

    return getLatestStatusFromList(equipmentStatuses, requiredStatus)
}

export const getLatestStatusFromList = (equipmentStatuses: EquipmentStatusDto[], requiredStatus?: EquipmentStatusEnum): EquipmentStatusDto | undefined => {
    if(requiredStatus)
        equipmentStatuses = equipmentStatuses.filter(es => es.status === requiredStatus)
    
    let copy = [...equipmentStatuses]
    copy.sort((a, b) => (new Date(a.validFromDate ?? '') >= new Date(b.validFromDate ?? '')) ? -1 : 1)

    if (copy.length < 1)
        return undefined

    return copy[0]
}

export const getEarliestStatus = (equipment?: EquipmentDto, requiredStatus?: EquipmentStatusEnum): EquipmentStatusDto | undefined => {
    if (!equipment ||
        (equipment.equipmentStatuses?.length ?? 0) < 1)
        return undefined

    let equipmentStatuses = equipment.equipmentStatuses ?? []

    return getEarliestStatusFromList(equipmentStatuses, requiredStatus)
}

export const getEarliestStatusFromList = (equipmentStatuses: EquipmentStatusDto[], requiredStatus?: EquipmentStatusEnum): EquipmentStatusDto | undefined => {
    if(requiredStatus)
        equipmentStatuses = equipmentStatuses.filter(es => es.status === requiredStatus)
    
    let copy = [...equipmentStatuses]
    copy?.sort((a, b) => (new Date(a.validFromDate ?? '') >= new Date(b.validFromDate ?? '')) ? 1 : -1)

    if (copy.length < 1)
        return undefined

    return copy[0]
}