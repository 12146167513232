import create from "zustand"
import { InventoryLocationCreateDto, InventoryLocationDto, InventoryLocationDtoPaginatedResponse, InventoryLocationUpdateDto } from "../api/Ferrolog"
import { ferrologApi } from "../App"
import { Pagination } from "../interface/PaginationInterface"

type State = {
    Pagination: Pagination
    setPagination: (pagination: Pagination) => void
    PaginationContainer: InventoryLocationDtoPaginatedResponse
    fetch: () => void
    fetchById: (id: number) => Promise<InventoryLocationDto>
    create: (inventoryLocation: InventoryLocationCreateDto) => Promise<void>
    update: (id: number, inventoryLocation: InventoryLocationUpdateDto) => Promise<void>
}

export const inventoryLocationStore = create<State>((set, get) => ({
    Pagination: { CurrentPage: 1, PageSize: 10 },
    setPagination: pagination => set({Pagination: pagination}),
    
    PaginationContainer: { currentPageItemCount: 0, items: [], paginationFilter: { currentPage: 1, pageSize: 10 }, totalItemCount: 0, totalPageCount: 1 },

    fetch: () => ferrologApi.inventoryLocationList(get().Pagination)
            .then(response => set({ PaginationContainer: response.data }))
            .catch(Error),

    fetchById: (id: number) => ferrologApi.inventoryLocationDetail(id)
        .then(response => response.data ),

    create: (inventoryLocation) => ferrologApi.inventoryLocationCreate(inventoryLocation)
        .then(() => get().fetch()),

    update: (id, inventoryLocation) => ferrologApi.inventoryLocationUpdate(id, inventoryLocation).then(() => get().fetch()),

}))