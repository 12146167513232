const ferrologTableStyle =
{
    tableMainContainer:
    {
        padding: 10,
        maxWidth: 1920
    },
    tableAddButton:
    {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 5 
    },
    tableMarginTop:
    {
        marginTop: 10
    },
    addButtonContainer:
    {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 5
    },
    downloadButtonContainer:
    {
        display: 'flex',
        justifyContent: "flex-end",
        maxWidth: 1920 
    },
    tableContainer:
    {
        padding: 10,
        maxWidth: '100%'
    },
    tableListText:
    {
        color: 'black',
        marginTop: 8,
        marginLeft: 10
    },
    tableListTextDisabled:
    {
        color: 'gray',
        marginTop: 8,
        marginLeft: 10
    },
    tableListCheckBox:
    {
        color: 'black',
        fontSize: 12,
        marginTop: 5,
        marginLeft: 10
    }
}
export default ferrologTableStyle
