import { TableCell, TableRow, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { EquipmentStatusDto } from "../api/Ferrolog";
import { EditMode } from "../components/EditMode";
import { pageContainerStore } from "../components/pageContainer/PageContainerStore";
import TabFragment from "../components/tabComponent/TabFragment";
import { calibrationLocationStore } from "../stores/CalibrationLocationStore";
import { equipmentStatusStore } from "../stores/EquipmentStatusStore";
import EquipmentStatusEdit from "./EquipmentStatusEdit";

interface EquipmentStatusTableRowProps {
    row: EquipmentStatusDto
}

export default function EquipmentStatusTableRow(props: EquipmentStatusTableRowProps) {
    const { row } = props
    const { getCalibrationLocationNameById } = calibrationLocationStore()
    const { readableStatus } = equipmentStatusStore()
    const theme = useTheme()

    const showAll = useMediaQuery(theme.breakpoints.up('xl'))
    const showMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
    const {addPage} = pageContainerStore()

    const ShowEquipmentTableRow = () =>
        <>
            <TableCell>{row.validFromDate?.toString() ?? ''}</TableCell>
            <TableCell>{row.status ? readableStatus(row.status) : 'Unknown'}</TableCell>
            <TableCell>{row.updatedBy}</TableCell>
            {
                showMediumScreen
                    ? <>
                        <TableCell></TableCell>
                        <TableCell>{getCalibrationLocationNameById(row.calibrationLocationId ?? 0)}</TableCell>

                    </>
                    : null
            }
            {
                showAll
                    ? <>
                        <TableCell>{row.comment}</TableCell>
                    </>
                    : null
            }
        </>

    return (
        <React.Fragment>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={row.id}
                onClick={() => addPage(
                    <TabFragment
                        EditPage={<EquipmentStatusEdit equipmentId={row.equipmentId!} editMode={EditMode.EditExisting} equipmentStatusId={row.id} />}
                        entityName={'EquipmentStatus'}
                        entityId={row.id as number}
                        editMode={EditMode.EditExisting}
                        CreatePage={<EquipmentStatusEdit editMode={EditMode.CreateNew} equipmentId={row.equipmentId!} />}
                    />)}
                style={{ cursor: 'pointer' }}
            >
                {
                    <ShowEquipmentTableRow />
                }
            </TableRow>
        </React.Fragment>
    )
}