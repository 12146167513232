import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import { ReactElement, useEffect, useState } from "react"
import { InventoryLocationCreateDto } from "../api/Ferrolog"
import { EditMode } from "../components/EditMode"
import { pageContainerStore } from "../components/pageContainer/PageContainerStore"
import { CreateCancelButton } from "../controls/CreateCancelButton"
import { countryCodesStore } from "../stores/CountryCodeStore"
import { inventoryLocationStore } from "../stores/InventoryLocationStore"

interface InventoryLocationEditProps {
    editMode: EditMode
    inventoryLocationId?: number
}

export default function InventoryLocationEdit(props: InventoryLocationEditProps): ReactElement {
    const { editMode, inventoryLocationId } = props

    const { startRemovingPage } = pageContainerStore()
    const { PaginationContainer, create, update } = inventoryLocationStore()
    const { PaginationContainer: countryCodesContainer } = countryCodesStore()

    const getFirstCountryCodeId = () => {
        if (countryCodesContainer.items && countryCodesContainer.items.length > 0)
            return countryCodesContainer.items[0].id!

        return 0
    }

    const emptyInventoryLocation = {
        name: '',
        street: '',
        city: '',
        countryId: getFirstCountryCodeId(),
        postcode: '',
    }

    const getSelectedInventoryLocation = () => PaginationContainer.items?.find(i => i.id === inventoryLocationId) ?? emptyInventoryLocation

    const [inventoryLocation, setInventoryLocation] = useState<InventoryLocationCreateDto>(inventoryLocationId ? getSelectedInventoryLocation() : emptyInventoryLocation)

    useEffect(() => {
        if (inventoryLocationId)
            setInventoryLocation(getSelectedInventoryLocation())
        else
            setInventoryLocation(emptyInventoryLocation)
    }, [inventoryLocationId])

    const createOrUpdateInventoryLocationChanges = () => {
        if (editMode === EditMode.EditExisting && inventoryLocationId !== null) {
            let Updateequipment = { ...inventoryLocation, id: inventoryLocationId as number }

            update(inventoryLocationId!, Updateequipment)
        }
        else {
            create(inventoryLocation)

            startRemovingPage()
        }
    }

    const makeTextField = (label: string, id: string, onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void, value?: string) =>
        <Grid item xs={12} md={6}>
            <div style={{ padding: 10 }}>
                <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>{label}</Typography>
                <TextField
                    fullWidth
                    id={id}
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </Grid>

    return (
        <Grid container>
            <CreateCancelButton createOrUpdateItem={createOrUpdateInventoryLocationChanges} />

            {makeTextField("Name", "name", (e => setInventoryLocation({ ...inventoryLocation, name: e.target.value })), inventoryLocation.name)}
            {makeTextField("Street", "street", (e => setInventoryLocation({ ...inventoryLocation, street: e.target.value })), inventoryLocation.street)}
            {makeTextField("City", "city", (e => setInventoryLocation({ ...inventoryLocation, city: e.target.value })), inventoryLocation.city)}
            {makeTextField("Postcode", "postcode", (e => setInventoryLocation({ ...inventoryLocation, postcode: e.target.value })), inventoryLocation.postcode)}

            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Country code</Typography>
                    <Select
                        id="countryId"
                        fullWidth
                        value={inventoryLocation.countryId}
                        onChange={e => setInventoryLocation({ ...inventoryLocation, countryId: e.target.value as number })}
                    >
                        {countryCodesContainer.items?.map(cc => <MenuItem key={cc.alpha2} value={cc.id}>{cc.alpha2}</MenuItem>)}
                    </Select>
                </div>
            </Grid>
        </Grid>
    )
}