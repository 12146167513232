import moment, { DurationInputArg2 } from 'moment'
import { EquipmentDto, EquipmentStatusEnum } from '../api/Ferrolog'
import { getLatestActiveStatus } from '../EquipmentStatus/EquipmentStatusHelpers'



export const calculateNextCalibration = (equipment?: EquipmentDto): string => {
    if (!equipment ||
        !equipment.lastCalibration)
        return ''

    return moment(equipment.lastCalibration)
        .add((equipment.calibrationInterval ?? 0), (equipment.calibrationIntervalType?.toLowerCase() ?? 'year') as DurationInputArg2)
        .toISOString()
}

export const calculateNextNotification = (equipment?: EquipmentDto): string => {
    if (!equipment ||
        !equipment.lastCalibration)
        return ''

    return moment(calculateNextCalibration(equipment))
        .subtract((equipment.notificationInterval ?? 0), (equipment.notificationIntervalType?.toLowerCase() ?? 'month') as DurationInputArg2)
        .toISOString()
}

export const hasValidCalibration = (equipment?: EquipmentDto): boolean => {
    if (!equipment)
        return false

    
    
    const nextCalibration = calculateNextCalibration(equipment)
    if(nextCalibration === '')
        return false

    const lastActiveStatus = getLatestActiveStatus(equipment)
    if(!lastActiveStatus)
        return false
    
    const now = moment()
    return now.isBefore(moment(nextCalibration)) && lastActiveStatus.status === EquipmentStatusEnum.Approved
}

export const showCalibrationNotification = (equipment?: EquipmentDto): boolean => {
    if (!equipment)
        return false;

    const now = moment()
    const nextNotification = calculateNextNotification(equipment)

    if(nextNotification === '')
        return false

    return now.isSameOrAfter(nextNotification);
}