import create from 'zustand'
import { FilesPaginatedResponse } from '../api/Ferrolog';
import { ferrologApi } from '../App';
import { Pagination } from "../interface/PaginationInterface";

export interface FileRequiredField {
    entityName: string,
    entityId: number,
    fileType: string,
    uploadByUser: string,
    uploadByDate: string
}

type State = {
    fileRequiredField: FileRequiredField
    Pagination: Pagination
    Files: FilesPaginatedResponse
    fileDownload: {}
    fetch: () => void
    fetchById: (id: number) => void
    displayPicture: (id: number) => Promise<void | string>
    createFile: (fileParam: any) => Promise<void | number>
    deleteFile: (id: number) => Promise<void | Response>
}

export const fileStore = create<State>((set, get) => ({
    fileRequiredField: { entityName: '', entityId: 1, fileType: '', uploadByUser: '', uploadByDate: '' },
    Pagination: { CurrentPage: 1, PageSize: 10 },
    fileDownload: {},
    Files: { currentPageItemCount: 0, items: [], paginationFilter: { currentPage: 1, pageSize: 10 }, totalItemCount: 0, totalPageCount: 1 },

    fetch: () => {
        ferrologApi.filesDetail2(get().fileRequiredField.entityName, get().fileRequiredField.entityId, get().Pagination)
            .then(response => set({ Files: response.data }))
            .catch(Error)
    },

    fetchById: (id: number) => ferrologApi.filesDetail(id)
        .then(response => {
            let contentDisposition = response.headers.get('Content-Disposition')
            let fileName = 'file'

            if (contentDisposition != null) {
                let match = contentDisposition.match(/;filename=(.+)/)
                if (match != null && match.length > 1)
                    fileName = match[1]
            }

            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again         
            })
        }),

    createFile: (fileParam) => {
        let data = {
            file: fileParam,
            fileType: get().fileRequiredField.fileType,
            uploadByUser: get().fileRequiredField.uploadByUser,
            uploadByDate: get().fileRequiredField.uploadByDate
        }
        return new Promise<void | number>((resolve, reject) => {
            ferrologApi.filesCreate(get().fileRequiredField.entityName, get().fileRequiredField.entityId, data)
                .then((response) => {
                get().fetch()
                resolve(response.data.id)
            })
        })
    },

    displayPicture: (id: number) => {
        return new Promise<void | string>((resolve, reject) => {
            ferrologApi.filesStreamDetail(id).then(response => resolve(response.url)).catch(error => reject(error));
        })
    },
    
    deleteFile: (id) => {
        return new Promise<void | Response>((resolve, reject) => {
            ferrologApi.filesDelete(id).then((response) => {
                get().fetch()
               resolve(response)
            })
        })
    }

}))