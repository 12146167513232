export const routes = {
    calibrationLocations: '/calibrationLocations',
    companies: '/companies',
    comments: '/comments',
    inventoryLocations: '/inventoryLocation',
    zoomPage:'/zoomViewer',
    equipments: '/equipments',
    home: '/home',
}

export const externalRoutes = {
    swagger: '/swagger/index.html',
}