import EquipmentTableRow from './components/EquipmentTableRow'
import { equipmentStore } from '../stores/EquipmentStore'
import { EquipmentDto } from '../api/Ferrolog'
import NonResponsiveTableComponent from "../components/nonResponsiveTableComponent/NonResponsiveTableComponent"
import PageContainer from "../components/pageContainer/PageContainer"
import EquipmentEdit from "./EquipmentEdit"
import { EditMode } from '../components/EditMode'
import TabFragment from '../components/tabComponent/TabFragment'

export default function EquipmentTable() {
    const columnHeaders = {
        showOnSmallScreen: [{ name: 'Status' , width: '5px'},{ name: 'Notification', width: '2px' },{ name: 'Id', width: '2px' }],
        showAlways: [{ name: 'Description' }, { name: 'Cateogry', width: '10%' }, { name: 'Location', width: '10%' }, { name: 'NextCalibration', width: '10%' }],
    }

    const renderRow = (item: EquipmentDto) => <EquipmentTableRow key={item.id} row={item} />

    return (
        <PageContainer
            header='Equipments'
            rootPage={
                <NonResponsiveTableComponent<EquipmentDto>
                    store={equipmentStore()}
                    columnHeaders={columnHeaders}
                    renderItem={renderRow}
                />
            }
            makeEditPage={(editMode: EditMode, selectedRowId?: number) =>
                <TabFragment
                EditPage={<EquipmentEdit editMode={editMode} equipmentId={selectedRowId} />}
                entityName={'Equipment'}
                entityId={selectedRowId as number}
                editMode={editMode}
                CreatePage={<EquipmentEdit editMode={EditMode.CreateNew} /> }
                />}
            />
    )
}