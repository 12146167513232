import create from "zustand"
import { CountryCodeDtoPaginatedResponse } from "../api/Ferrolog"
import { ferrologApi } from "../App"
import { Pagination } from "../interface/PaginationInterface"

type State = {
    Pagination: Pagination
    PaginationContainer: CountryCodeDtoPaginatedResponse
    fetch: () => void

    getCountryCodeAlpha2ById: (id: number) => string
}

export const countryCodesStore = create<State>((set, get) => ({
    Pagination: { CurrentPage: 1, PageSize: 10 },
    PaginationContainer: { currentPageItemCount: 0, items: [], paginationFilter: { currentPage: 1, pageSize: 10 }, totalItemCount: 0, totalPageCount: 1 },

    fetch: () => ferrologApi.countryCodesList(get().Pagination)
            .then(response => set({ PaginationContainer: response.data }))
            .catch(Error),

    getCountryCodeAlpha2ById: (id: number) => {
        const foundCountryCode = get().PaginationContainer.items?.find(i => i.id === id)
        return foundCountryCode ? foundCountryCode.alpha2 ?? '' : 'Not found'
    }
}))