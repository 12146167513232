import { Grid,  MenuItem, Select, TextField, Typography } from "@mui/material"
import { ReactElement, useEffect, useState } from "react"
import { CalibrationLocationCreateDto, InventoryLocationCreateDto } from "../api/Ferrolog"
import { EditMode } from "../components/EditMode"
import { pageContainerStore } from "../components/pageContainer/PageContainerStore"
import { CreateCancelButton } from "../controls/CreateCancelButton"
import { calibrationLocationStore } from "../stores/CalibrationLocationStore"
import { countryCodesStore } from "../stores/CountryCodeStore"

interface CalibrationLocationEditProps {
    editMode: EditMode
    calibrationLocationId?: number
}

export default function CalibrationLocationEdit(props: CalibrationLocationEditProps): ReactElement {
    const { editMode, calibrationLocationId } = props

    const { startRemovingPage } = pageContainerStore()
    const { PaginationContainer, create, update } = calibrationLocationStore()
    const { PaginationContainer: countryCodesContainer } = countryCodesStore()

    const getFirstCountryCodeId = () => {
        if (countryCodesContainer.items && countryCodesContainer.items.length > 0)
            return countryCodesContainer.items[0].id!

        return 0
    }

    const emptyCalibrationLocation: CalibrationLocationCreateDto = {
        name: '',
        street: '',
        city: '',
        countryId: getFirstCountryCodeId(),
        postcode: '',
        contactName: '',
        phoneNumber: '',
    }

    const getSelectedCalibrationLocation = () => PaginationContainer.items?.find(i => i.id === calibrationLocationId) as CalibrationLocationCreateDto ?? emptyCalibrationLocation

    const [calibrationLocation, setCalibrationLocation] = useState<CalibrationLocationCreateDto>(calibrationLocationId ? getSelectedCalibrationLocation() : emptyCalibrationLocation)

    useEffect(() => {
        if (calibrationLocationId)
            setCalibrationLocation(getSelectedCalibrationLocation())
        else
            setCalibrationLocation(emptyCalibrationLocation)
    }, [calibrationLocationId])

    const createOrUpdateCalibrationLocationChanges = () => {
        if (editMode === EditMode.EditExisting && calibrationLocationId !== null) {
            let Updateequipment = { ...calibrationLocation, id: calibrationLocationId as number }

            update(calibrationLocationId!, Updateequipment)
        }
        else {
            create(calibrationLocation)

            startRemovingPage()
        }
    }

    const makeTextField = (label: string, id: string, onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void, value?: string) =>
        <Grid item xs={12} md={6}>
            <div style={{ padding: 10 }}>
                <Typography variant={"body2"} style={{ fontWeight: 'bold' }} component={'span'} color='primary'>{label}</Typography>
                <TextField
                    fullWidth
                    id={id}
                    multiline
                    maxRows={4}
                    value={value}
                    onChange={onChange}
                />
            </div>
        </Grid>

    return (
        <Grid container >
            <CreateCancelButton createOrUpdateItem={createOrUpdateCalibrationLocationChanges} />

            {makeTextField("Name", "name", (e => setCalibrationLocation({ ...calibrationLocation, name: e.target.value })), calibrationLocation.name)}
            {makeTextField("Street", "street", (e => setCalibrationLocation({ ...calibrationLocation, street: e.target.value })), calibrationLocation.street)}
            {makeTextField("City", "city", (e => setCalibrationLocation({ ...calibrationLocation, city: e.target.value })), calibrationLocation.city)}
            {makeTextField("Postcode", "postcode", (e => setCalibrationLocation({ ...calibrationLocation, postcode: e.target.value })), calibrationLocation.postcode)}

            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Country code</Typography>
                    <Select
                        id="countryId"
                        fullWidth
                        value={calibrationLocation.countryId}
                        onChange={e => setCalibrationLocation({ ...calibrationLocation, countryId: e.target.value as number })}
                    >
                        {countryCodesContainer.items?.map(cc => <MenuItem key={cc.alpha2} value={cc.id}>{cc.alpha2}</MenuItem>)}
                    </Select>
                </div>
            </Grid>
            <Grid item xs={0} md={6}>
            </Grid>

            {makeTextField("Contact Name", "contact-name", (e => setCalibrationLocation({ ...calibrationLocation, contactName: e.target.value })), calibrationLocation.contactName)}
            {makeTextField("Phone number", "phone-number", (e => setCalibrationLocation({ ...calibrationLocation, phoneNumber: e.target.value })), calibrationLocation.phoneNumber)}

        </Grid>
    )
}