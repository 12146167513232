import { ReactElement } from 'react'
import create from 'zustand'

type State = {
    rootPage: ReactElement,
    setRootPage: (rootPage: ReactElement) => void

    selectedRowId?: number
    setSelectedRowId: (rowId?: number) => void

    searchString?: string,
    setSearchString: (search?: string) => void

    activePageIndex: number
    setActivePageIndex: (index: number) => void
    
    numberOfPagesTransition: number
    startRemovingPage: () => void

    pagesInternal: ReactElement[]
    setPagesInternal: (pages: ReactElement[]) => void

    pages: ReactElement[]

    addPage: (page: ReactElement) => void
    removePage: (page: ReactElement) => void
}

export const pageContainerStore = create<State>((set, get) => ({
    rootPage: (<div></div>),
    setRootPage: (rootPage: ReactElement) => set({
        rootPage: rootPage,
        pages: [rootPage].concat(get().pagesInternal)
    }),

    selectedRowId: 0,
    setSelectedRowId: (rowId?: number) => set({ selectedRowId: rowId }),

    searchString: '',
    setSearchString: (search?: string) => set({ searchString: search}),

    activePageIndex: 0,
    setActivePageIndex: (index: number) => set({ activePageIndex: index }),

    numberOfPagesTransition: 1,
    startRemovingPage: () => set({numberOfPagesTransition: get().numberOfPagesTransition - 1}),

    pages: [],
    pagesInternal: [],

    setPagesInternal: (pages) => set({
        pagesInternal: pages,
        pages: [get().rootPage].concat(pages),
        numberOfPagesTransition: pages.length,
        activePageIndex: pages.length
    }),

    addPage: (page: ReactElement) =>
        set({
            pagesInternal: get().pagesInternal.concat(page),
            pages: get().pages.concat(page),
            activePageIndex: get().pages.length,
            numberOfPagesTransition: get().pages.length
        }),

    removePage: (page: ReactElement) =>
        set({
            pagesInternal: get().pagesInternal.filter(p => p !== page),
            pages: get().pages.filter(p => p !== page),
            activePageIndex: get().activePageIndex - 1
        }),
}))
