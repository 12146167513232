const dragDropStyle =
{
    backgroundTransparent:
    {
        backgroundColor: 'transparent',
        opacity: 0.7,
        zIndex: 0,
        margin: 5,
        postion: 'fixed',
        height: '100vh',
    },
    dragDropCenter:
    {
        postion: 'absolute',
        right: '0px',
        left: '0px',
        top: '0px',
        bottom: '0px',
        margin: 'auto',
    },
    dragDropBox:
    {
        backgroundColor: '#e9ecef',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.46,
        shadowRadius: 11.14,
        height: '50vh',
        maxWidth: 1920,
        postion: 'absolute',
        elevation: 17,
    },
    dragDropText:
    {
        fontWeight: 'bold',
    }
}

export default dragDropStyle