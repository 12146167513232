import { Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CompanyCreateDto } from "../api/Ferrolog"
import { companyStore } from "../stores/CompanyStore"
import { EditMode } from "../components/EditMode"
import { pageContainerStore } from "../components/pageContainer/PageContainerStore"
import { CreateCancelButton } from "../controls/CreateCancelButton";

interface CompanyEditProps {
    editMode: EditMode
    companyId?: number
}

export default function CompanyEdit(props: CompanyEditProps) {

    const { editMode, companyId } = props

    const { create, updateCompany, PaginationContainer } = companyStore()
    const { startRemovingPage } = pageContainerStore()

    const emptyCompany = {
        name: '',
        address: '',
        zip: '',
        city: '',
        phoneNr: '',
        web: '',
        email: '',
    }

    const getSelectedCompany = () => PaginationContainer.items?.find(i => i.id === companyId) ?? emptyCompany

    const [company, setCompany] = useState<CompanyCreateDto>(companyId ? getSelectedCompany() : emptyCompany)

    useEffect(() => {
        if (companyId)
            setCompany(getSelectedCompany)
        else
            setCompany(emptyCompany)
    }, [companyId])

    const createUpdateCompanyChanges = () => {
        if (editMode === EditMode.EditExisting && companyId !== null) {
            let updateCompanyData = { ...company, id: companyId as number }

            updateCompany(companyId as number, updateCompanyData)
        }
        else {
            create(company)

            startRemovingPage()
        }

    }

    return (
        <Grid container>
            <CreateCancelButton createOrUpdateItem={createUpdateCompanyChanges} />
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span' } style={{ fontWeight: 'bold' }} color='primary'>Name</Typography>
                    <TextField
                        fullWidth
                        value={company.name}
                        onChange={e => setCompany({ ...company, name: e.target.value })}
                        id="type"
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Address</Typography>
                    <TextField
                        fullWidth
                        value={company.address}
                        onChange={e => setCompany({ ...company, address: e.target.value })}
                        id="type"
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>City</Typography>
                    <TextField
                        fullWidth
                        value={company.city}
                        onChange={e => setCompany({ ...company, city: e.target.value })}
                        id="type"
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Zip</Typography>
                    <TextField
                        fullWidth
                        value={company.zip}
                        onChange={e => setCompany({ ...company, zip: e.target.value })}
                        id="type"
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Phone Number</Typography>
                    <TextField
                        fullWidth
                        value={company.phoneNr}
                        onChange={e => setCompany({ ...company, phoneNr: e.target.value })}
                        id="type"
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Web</Typography>
                    <TextField
                        fullWidth
                        value={company.web}
                        onChange={e => setCompany({ ...company, web: e.target.value })}
                        id="type"
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Email</Typography>
                    <TextField
                        fullWidth
                        value={company.email}
                        onChange={e => setCompany({ ...company, email: e.target.value })}
                        id="type"
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6} />
        </Grid>
    )
}