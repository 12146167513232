import { EquipmentStatusDto } from '../api/Ferrolog'
import NonResponsiveTableComponent from "../components/nonResponsiveTableComponent/NonResponsiveTableComponent"
import EquipmentStatusTableRow from './EquipmentStatusTableRow'
import { equipmentStatusStore } from '../stores/EquipmentStatusStore'
import { calibrationLocationStore } from '../stores/CalibrationLocationStore'
import { useEffect } from 'react'

export default function EquipmentTable() {
    const { fetch } = calibrationLocationStore()

    useEffect(() => {
        fetch()
    }, [])

    const columnHeaders =
    {
        showOnSmallScreen: [{ width: '10%', name: 'Date' },{ width: '10%', name: 'Status' },{ width: '20%', name: 'Modified By' }],
        showAlways: [{ width: '10%', name: 'Attachments' }, { width: '20%', name: 'Calibration Location' },{ width: '20%', name: 'Comment' }],
    }

    const renderRow = (item: EquipmentStatusDto) => <EquipmentStatusTableRow key={item.id} row={item} />

    return (
        <NonResponsiveTableComponent<EquipmentStatusDto>
            store={equipmentStatusStore()}
            columnHeaders={columnHeaders}
            renderItem={renderRow}
            entityName = 'EquipmentStatus'
        />
    )
}