import { Grid, IconButton, Select, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ReactElement, useEffect, useState } from "react"
import { EquipmentStatusCreateDto, EquipmentStatusDto, EquipmentStatusEnum } from "../api/Ferrolog"
import { EditMode } from "../components/EditMode"
import { pageContainerStore } from "../components/pageContainer/PageContainerStore"
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { equipmentStatusStore } from "../stores/EquipmentStatusStore"
import { calibrationLocationStore } from "../stores/CalibrationLocationStore"
import { DateTimeTextField } from "../controls/DateTimeTextField"
import { EquipmentStatusSelect, getPossibleNewStates } from "../controls/EquipmentStatusSelect"
import { getEarliestStatus, getLatestActiveStatus, getLatestStatus } from "./EquipmentStatusHelpers"
import { equipmentStore } from "../stores/EquipmentStore"

interface EquipmentStatusEditProps {
    editMode: EditMode
    equipmentId: number
    equipmentStatusId?: number
}

export default function EquipmentStatusEdit(props: EquipmentStatusEditProps): ReactElement {
    const { editMode, equipmentId, equipmentStatusId } = props
    const { startRemovingPage } = pageContainerStore()

    const theme = useTheme()
    const showSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const now = new Date()

    const { getSelectedEquipment, addEquipmentStatus } = equipmentStore()
    const selectedEquipment = getSelectedEquipment(equipmentId)
    const latestActiveStatus = getLatestActiveStatus(selectedEquipment)
    const latestStatus = getLatestStatus(selectedEquipment)
    const earliestStatus = getEarliestStatus(selectedEquipment)

    const emptyEquipmentState: EquipmentStatusCreateDto = {
        equipmentId: equipmentId,
        validFromDate: now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-' + String(now.getDate()).padStart(2, '0'),
        status: EquipmentStatusEnum.Calibrated,
        calibrationLocationId: undefined,
        comment: ''
    }

    const { PaginationContainer, create, update } = equipmentStatusStore()

    const { fetch: fetchCalibrationLocations, PaginationContainer: calibrationLocations } = calibrationLocationStore()

    useEffect(() => fetchCalibrationLocations(), [])

    const getSelectedEquipmentStatus = (): EquipmentStatusCreateDto => PaginationContainer.items?.find(i => i.id === equipmentStatusId) as EquipmentStatusCreateDto ?? emptyEquipmentState

    const [equipmentStatus, setEquipmentStatus] = useState<EquipmentStatusCreateDto>({ ...getSelectedEquipmentStatus(), status: getPossibleNewStates(selectedEquipment?.equipmentStatuses)[0] })

    const equipmentStatusReadOnly = (): EquipmentStatusDto | undefined => PaginationContainer.items?.find(i => i.id === equipmentStatusId)

    const createOrUpdateEquipmentStateChanges = () => {
        if (editMode === EditMode.EditExisting && equipmentStatusId !== null && equipmentStatusId)
            update(equipmentStatusId, { ...equipmentStatus, id: equipmentStatusId })
        else {
            create(equipmentStatus).then(result => {
                if (result)
                    addEquipmentStatus(equipmentId, result)
            })

            startRemovingPage()
        }
    }

    const showCalibrationLocation = equipmentStatus.status === EquipmentStatusEnum.Calibrated || equipmentStatus.status === EquipmentStatusEnum.SentForCalibration
    const inputMediumWidth = showCalibrationLocation ? 4 : 6

    const minNewStatusDate = (latestStatus?.validFromDate ?? '1900-01-01').substring(0, 10)
    const maxNewStatusDate = (earliestStatus?.validFromDate ?? '2999-01-01').substring(0, 10)

    const getDateFilter = () => equipmentStatus.status === EquipmentStatusEnum.Purchased
        ? { max: maxNewStatusDate }
        : { min: minNewStatusDate }

    return (
        <Grid container>
            <Grid item xs={6}>
                <div style={showSmallScreen && editMode === EditMode.CreateNew ? { marginTop: '40%' } : { padding: 10 }}>
                        <IconButton color="error" onClick={() => startRemovingPage()}>
                            <ClearIcon fontSize="large" />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={6}>
                <div style={showSmallScreen && editMode === EditMode.CreateNew ?  { marginTop: '40%' } : { padding: 10 }}>
                        <IconButton
                            color='success'
                            onClick={() => createOrUpdateEquipmentStateChanges()}
                            disabled={equipmentStatus.status === EquipmentStatusEnum.Calibrated && !equipmentStatus.calibrationLocationId}
                        >
                            <CheckIcon fontSize="large" />
                        </IconButton>
                    </div>
                </Grid>

            {latestActiveStatus &&
                <>
                    <Grid item xs={6} md={4}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Current Status</Typography>
                            <EquipmentStatusSelect
                                fullWidth
                                value={latestActiveStatus.status}
                                id="status"
                                disabled={true}
                            >
                            </EquipmentStatusSelect>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Current Status Date</Typography>
                            <TextField
                                fullWidth
                                type="date"
                                id="validFromDate"
                                value={latestActiveStatus.validFromDate}
                                disabled={true}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={0} md={4}>
                    </Grid>
                </>
            }

            <Grid item xs={6} md={inputMediumWidth}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>New Status</Typography>
                    <EquipmentStatusSelect
                        fullWidth
                        value={equipmentStatus.status}
                        id="status"
                        onChange={e => {
                            let newStatus = e.target.value as EquipmentStatusEnum
                            let newValidFromDate = equipmentStatus.validFromDate

                            if (newStatus === EquipmentStatusEnum.Purchased && (new Date(maxNewStatusDate) < now))
                                newValidFromDate = maxNewStatusDate

                            if (newStatus !== EquipmentStatusEnum.Purchased && new Date(minNewStatusDate) > new Date(newValidFromDate))
                                newValidFromDate = minNewStatusDate

                            setEquipmentStatus({
                                ...equipmentStatus,
                                status: newStatus,
                                validFromDate: newValidFromDate
                            })
                        }}
                        existingStatuses={selectedEquipment?.equipmentStatuses}
                    >
                    </EquipmentStatusSelect>
                </div>
            </Grid>
            <Grid item xs={6} md={inputMediumWidth}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>New Status Date</Typography>
                    <TextField
                        fullWidth
                        type="date"
                        id="validFromDate"
                        value={equipmentStatus.validFromDate}
                        onChange={e => setEquipmentStatus({ ...equipmentStatus, validFromDate: e.target.value })}
                        inputProps={getDateFilter()}
                    />
                </div>
            </Grid>
            {
                showCalibrationLocation &&
                <Grid item xs={6} md={4}>
                    <div style={{ padding: 10 }}>
                        <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Calibration Location</Typography>
                        <Select native fullWidth
                            value={equipmentStatus.calibrationLocationId}
                            id="inventory-location"
                            onChange={e => setEquipmentStatus({ ...equipmentStatus, calibrationLocationId: Number(e.target.value) })}
                        >
                            <option key='cl-0' value={0}>please select</option>
                            {calibrationLocations.items?.map(cl => <option key={'cl-' + cl.id} value={cl.id}>{cl.name}</option>)}
                        </Select>
                    </div>
                </Grid>
            }

            {editMode === EditMode.EditExisting &&
                <>
                    <Grid item xs={6}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Modified By</Typography>
                            <TextField
                                fullWidth
                                id="modifiedBy"
                                type="text"
                                InputProps={{ readOnly: true }}
                                disabled
                                value={equipmentStatusReadOnly()?.updatedBy}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ padding: 10 }}>
                            <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Time Stamp</Typography>
                            <DateTimeTextField
                                fullWidth
                                id="timestamp"
                                InputProps={{ readOnly: true }}
                                disabled
                                value={equipmentStatusReadOnly()?.updatedAt}
                            />
                        </div>
                    </Grid>
                </>}

            <Grid item xs={12}>
                <div style={{ padding: 10 }}>
                    <Typography variant="subtitle1" component={'span'} style={{ fontWeight: 'bold' }} color='primary'>Comment</Typography>
                    <TextField
                        fullWidth
                        id="comment"
                        type="text"
                        multiline={true}
                        rows={4}
                        value={equipmentStatus.comment}
                        onChange={e => setEquipmentStatus({ ...equipmentStatus, comment: e.target.value })}
                    />
                </div>
            </Grid>
        </Grid>
    )
}