import { AddBox } from '@mui/icons-material'
import { AppBar, Box, Button, debounce, IconButton, InputBase, Paper, Slide, styled, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FunctionComponent, ReactElement, useEffect } from "react"
import { EditMode } from '../EditMode'
import { pageContainerStore } from './PageContainerStore'
import SearchIcon from '@mui/icons-material/Search';

export interface PageContainerProps {
    header: string
    rootPage: ReactElement
    makeEditPage: (editMode: EditMode, selectedRowId?: number) => ReactElement
}

const PageContainer: FunctionComponent<PageContainerProps> = (props) => {
    const theme = useTheme()

    const { header, rootPage, makeEditPage } = props

    const showSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const { setSelectedRowId, setSearchString } = pageContainerStore()

    const { setRootPage, selectedRowId, activePageIndex, setActivePageIndex, numberOfPagesTransition, pages, setPagesInternal, removePage } = pageContainerStore()

    useEffect(() => setRootPage(rootPage), [rootPage, setRootPage])

    useEffect(() => {
        if (selectedRowId == 0)
            return

        setPagesInternal([makeEditPage(EditMode.EditExisting, selectedRowId)])

    }, [selectedRowId, setPagesInternal, makeEditPage])

    const createNewItem = () =>
    {
        setPagesInternal([makeEditPage(EditMode.CreateNew)])

        setSelectedRowId(undefined)
    }

    const handleOnChangeSearchParam = debounce(value =>  setSearchString(value) , 1000)

    const slidingGrid = (pageIndex: number, page: ReactElement) => {
        if (pages.length < pageIndex)
            return null

        const last = pageIndex === pages.length - 1
        const calculatedMarginLeft = (50.0 / pages.length) * pageIndex

        return (
            <Slide
                key={pageIndex.toString()}
                direction='left'
                in={numberOfPagesTransition >= pageIndex}
                onExited={() => removePage(page)}
                appear={pageIndex > 0}
                style={!showSmallScreen ? {
                    marginTop: '64px',
                    marginLeft: (last ? 'auto' : calculatedMarginLeft + '%'),
                    right: (last && pageIndex > 0 ? '0px' : 'auto'),
                    position: 'absolute',
                    zIndex: (pageIndex === activePageIndex ? 999 : pageIndex),
                    width: '50%',
                    maxWidth: '50%',
                } : { padding: 10, position: 'absolute', zIndex: (pageIndex === activePageIndex ? 999 : pageIndex) }}>
                <Item style={!showSmallScreen ? { maxWidth: '50%' } : { maxWidth: '100%' }}>
                    {page}
                </Item>
            </Slide >
        )
    }

    const appBar = () => (
        <AppBar component="nav" style={{ backgroundColor: 'white', zIndex: 10000 }}>
            <Toolbar>
                <Typography
                    variant="h6"
                    component="span"
                    style={{ color: 'black', display: 'flex', flexDirection: 'row' }}
                    flexGrow={1}>
                    {header}
                    <Paper
                        sx={{ display: 'flex', alignItems: 'center', width: 400, marginLeft: '10px' }}>
                    <InputBase
                            sx={{ ml: '3px', flex: 1 }}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={e => handleOnChangeSearchParam(e.target.value)}
                    />
                        <SearchIcon />
                    </Paper>
                </Typography>
                <Box>
                    <IconButton  color='primary' onClick={() => createNewItem()}><AddBox /></IconButton>
                    <Button onClick={() => setActivePageIndex(0)}>|&lt;</Button>
                    <Button onClick={() => setActivePageIndex(activePageIndex - 1)}>&lt;&lt;</Button>
                    <Button onClick={() => setActivePageIndex(activePageIndex + 1)}>&gt;&gt;</Button>
                    <Button onClick={() => setActivePageIndex(pages.length - 1)}>&gt;|</Button>
                </Box>
            </Toolbar>
        </AppBar>
    )

    return (
        <div >
            {appBar()}

            {pages.map((page, index) => slidingGrid(index, page))}
        </div>
    )
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default PageContainer