import create from "zustand";
import { CalibrationLocationCreateDto, CalibrationLocationDto, CalibrationLocationDtoPaginatedResponse } from "../api/Ferrolog";
import { ferrologApi } from "../App";
import { Pagination } from "../interface/PaginationInterface";

type State = {
    Pagination: Pagination
    setPagination: (pagination: Pagination) => void
    PaginationContainer: CalibrationLocationDtoPaginatedResponse
    fetch: () => void
    fetchById: (id: number) => Promise<CalibrationLocationDto>
    create: (calibrationLocation: CalibrationLocationCreateDto) => Promise<void>
    update: (id: number, calibrationLocation: CalibrationLocationDto) => Promise<void>

    getCalibrationLocationNameById: (id: number) => string
}

export const calibrationLocationStore = create<State>((set, get) => ({
    Pagination: { CurrentPage: 1, PageSize: 10 },
    setPagination: pagination => set({Pagination: pagination}),
    
    PaginationContainer: { items: [], currentPageItemCount: 0, paginationFilter: { currentPage: 1, pageSize: 10 }, totalItemCount: 0, totalPageCount: 0 },

    fetch: () => {
        ferrologApi.calibrationLocationsList(get().Pagination)
            .then(response => { set({ PaginationContainer: response.data }) })
            .catch(error => console.error(error))
    },

    create: (calibrationLocation: CalibrationLocationCreateDto) => ferrologApi.calibrationLocationsCreate(calibrationLocation)
        .then(() => get().fetch()),

    fetchById: (id: number) => ferrologApi.companyDetail(id)
        .then(response => response.data),

    update: (id, calibrationLocation: CalibrationLocationDto) => ferrologApi.calibrationLocationsUpdate(id, calibrationLocation).then(() => get().fetch()),

    getCalibrationLocationNameById: (id: number) => {
        if (id === 0)
            return ''

        const foundCalibrationLocation = get().PaginationContainer.items?.find(i => i.id === id)
        return foundCalibrationLocation ? foundCalibrationLocation.name ?? '' : 'Not found'
    }
}))