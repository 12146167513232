import { AddBox } from "@mui/icons-material";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, useMediaQuery, useTheme } from '@mui/material'
import React, { ReactElement, useState } from "react";
import ferrologTableStyle from "./ferrologTableStyle";
import { useNavigate } from 'react-router-dom'
import { Pagination } from '../../interface/PaginationInterface';
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

interface PaginationContainer<T> {
    items?: T[],
    currentPageItemCount?: number,
    paginationFilter?: { currentPage?: number, pageSize?: number },
    totalItemCount?: number,
    totalPageCount?: number
}

interface ColumnHeaders {
    showAlways: string[],
    showOnMediumScreen: string[]
    showOnSmallScreen: string[]
    showOnAllScreen: string[]
}

interface TableProps<T> {
    paginationContainer: PaginationContainer<T>
    fetch: () => void
    Pagination: any
    navigationRoutName: string
    columnHeaders: ColumnHeaders
    renderItem: (subItem: T) => JSX.Element
}

const TableComponent = <T extends unknown>(props: TableProps<T>): ReactElement => {
    const { paginationContainer,
        navigationRoutName,
        fetch,
        Pagination,
        columnHeaders,
        renderItem
    } = props
    const theme = useTheme()

    const navigate = useNavigate()

    const showDropDown = useMediaQuery(theme.breakpoints.down('xl'))
    const showAll = useMediaQuery(theme.breakpoints.up('xl'))
    const showMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
    const showSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

    const [currentPagination, setCurrentPagination] = useState<Pagination>({
        CurrentPage: 1,
        PageSize: 10
    })
   
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number,) => {
        let pageChanage = newPage + 1

        Pagination.CurrentPage = pageChanage
        Pagination.PageSize = currentPagination.PageSize

        setCurrentPagination({ ...currentPagination, CurrentPage: pageChanage })
        fetch()
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        let rowsPerPageInteger = parseInt(event.target.value, 10)

        setCurrentPagination({ PageSize: rowsPerPageInteger, CurrentPage: 1 })

        Pagination.PageSize = rowsPerPageInteger
        Pagination.CurrentPage = 1

        fetch()
    }

    return (
        <div style={ferrologTableStyle.tableMainContainer}>
            {
                navigationRoutName === ''
                    ?
                    null :
                    <div style={ferrologTableStyle.addButtonContainer}>
                        <Button variant="contained" color='primary' startIcon={<AddBox />} onClick={() => navigate(navigationRoutName)}>
                            Neuen Eintrag Erstellen
                        </Button>
                    </div>
            }
            <TableContainer component={Paper} style={{ marginTop: 10 }} aria-label="custom pagination table">
                <Table sx={{ minWidth: 400, borderRadius: 2 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                showDropDown
                                    ? <TableCell></TableCell>
                                    : null
                            }
                            {
                                columnHeaders.showOnAllScreen.map((headers) => (
                                    <TableCell key={headers}>{headers}</TableCell>
                                ))
                            }
                            {
                                showSmallScreen
                                    ?
                                    <>
                                        {
                                            columnHeaders.showOnSmallScreen.map((headers) => (
                                                <TableCell key={headers} align="right">{headers}</TableCell>
                                            ))
                                        }
                                    </>
                                    : null
                            }
                            {
                                showMediumScreen
                                    ? <>
                                        {
                                            columnHeaders.showOnMediumScreen.map((headers) => (
                                                <TableCell key={headers} align="right">{headers}</TableCell>
                                            ))
                                        }

                                    </>
                                    : null
                            }
                            {
                                showAll
                                    ? <>
                                        {
                                            columnHeaders.showAlways.map((headers) => (
                                                <TableCell key={headers} align="right">{headers}</TableCell>
                                            ))
                                        }

                                    </>
                                    : null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {
                                paginationContainer.items?.map((rows) => renderItem(rows))
                            }
                        </>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                colSpan={3}
                                count={paginationContainer.totalItemCount ?? 0}
                                rowsPerPage={currentPagination.PageSize}
                                page={currentPagination.CurrentPage - 1}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    )
}

export default TableComponent