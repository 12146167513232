import create from "zustand";
import { CompanyCreateDto, CompanyDto, CompanyDtoPaginatedResponse, CompanyUpdateDto } from "../api/Ferrolog";
import { ferrologApi } from "../App";
import { Pagination } from "../interface/PaginationInterface";

type State = {
    Pagination: Pagination
    setPagination: (pagination: Pagination) => void
    PaginationContainer: CompanyDtoPaginatedResponse
    fetch: () => void
    fetchById: (id: number) => Promise<CompanyDto>
    create: (company: CompanyCreateDto) => Promise<void>
    updateCompany: (id: number, company: CompanyUpdateDto) => Promise<void>
}

export const companyStore = create<State>((set, get) => ({
    Pagination: { CurrentPage: 1, PageSize: 10 },
    setPagination: pagination => set({Pagination: pagination}),
    
    PaginationContainer: { items: [], currentPageItemCount: 0, paginationFilter: { currentPage: 1, pageSize: 10 }, totalItemCount: 0, totalPageCount: 0 },

    fetch: () => {
        ferrologApi.companyList(get().Pagination)
            .then(response => { set({ PaginationContainer: response.data }) })
            .catch(Error => console.log('display error', Error))
    },

    create: (company) => ferrologApi.companyCreate(company)
        .then(() => get().fetch()),

    fetchById: (id: number) => ferrologApi.companyDetail(id)
        .then(response => response.data),

    updateCompany: (id, company) => ferrologApi.companyUpdate(id, company).then(() => get().fetch()),

}))