import * as React from 'react'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Box, IconButton, Typography, useTheme, useMediaQuery } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CommentIcon from '@mui/icons-material/Comment'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { EditMode } from '../EditMode'
import { DragAndDrop } from '../fileDragAndDrop/DragAndDrop'
import Comment from "../../comment/Comment"
import EditIcon from '@mui/icons-material/Edit'
import { pageContainerStore } from '../pageContainer/PageContainerStore'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

interface TabProps {
    EditPage: React.ReactNode
    entityName: string
    entityId: number
    editMode: EditMode
    CreatePage: React.ReactNode
}

export default function TabFragment(props: TabProps) {
    const theme = useTheme()

    const { startRemovingPage, setSelectedRowId } = pageContainerStore()

    const { EditPage, entityId, entityName, editMode, CreatePage } = props

    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

    const showSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const cancelButton = () => {
        startRemovingPage()
        setSelectedRowId(0)
    }

    return (
        <div>
            {entityId ?
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: (showSmallScreen ? '15%' : 0) }}>
                        <IconButton color="error" onClick={() => cancelButton()}
                            style={{ height: '30px', width: '30px', marginTop: '5px', padding: '20px', marginRight: '5px' }}>
                            <ArrowForwardIosIcon fontSize="medium" />
                        </IconButton>
                        <Tabs style={{ height: '60px' }}
                            value={value}
                            onChange={handleChange}>
                            <Tab icon={<EditIcon />} value={0} />
                            <Tab icon={<AttachFileIcon />} value={1} />
                            {
                                entityName !== 'EquipmentStatus' && <Tab icon={<CommentIcon />} value={2} />
                            }
                            {
                                entityName === 'Equipment' && <Tab icon={<InfoOutlinedIcon />} value={3} />
                            }
                        </Tabs>
                    </div>

                    <TabPanel value={value} index={0}>
                            {EditPage}
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                            <DragAndDrop entityId={entityId as number} entityName={entityName} edit={editMode === EditMode.EditExisting}>
                            </DragAndDrop>
                    </TabPanel>
                    {
                        entityName !== 'EquipmentStatus' &&
                        <TabPanel value={value} index={2}>
                                <Comment entityId={entityId as number} entityName={entityName} />
                        </TabPanel>
                    }
                </div>
                :
                CreatePage
            }
        </div>
    );
}