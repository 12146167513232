import { CompanyDto } from "../api/Ferrolog"
import { companyStore } from "../stores/CompanyStore"
import CompanyTableRow from "./components/CompanyTableFragment"
import PageContainer from "../components/pageContainer/PageContainer"
import CompanyEdit from "./CompanyEdit"
import { EditMode } from "../components/EditMode"
import NonResponsiveTableComponent from "../components/nonResponsiveTableComponent/NonResponsiveTableComponent"
import TabFragment from "../components/tabComponent/TabFragment"

export default function CompanyTable() {
    const columnHeaders = {
        showOnSmallScreen: [{ name: 'Name' }, { name: 'Address' }, { name: 'Zip' }],
        showAlways: [{ name: 'City' }, { name: 'PhoneNumber' }, { name: 'Web' }, { name: 'Email' }],
    }

    const renderRow = (item: CompanyDto) => <CompanyTableRow key={item.id} row={item} />

    return (
        <PageContainer
            header='Companies'
            rootPage={
                <NonResponsiveTableComponent<CompanyDto>
                    store={companyStore()}
                    columnHeaders={columnHeaders}
                    renderItem={renderRow}
                />
            }
            makeEditPage={(editMode: EditMode, selectedRowId?: number) =>
                <TabFragment
                    EditPage={<CompanyEdit editMode={editMode} companyId={selectedRowId} />}
                    entityName={'Comapny'}
                    entityId={selectedRowId as number}
                    editMode={editMode}
                    CreatePage={<CompanyEdit editMode={EditMode.CreateNew} />}
                />}
           />
    )
}