import React from 'react'
import { Select, SelectProps } from "@mui/material"
import { CalibrationIntervalEnum, EquipmentStatusDto, EquipmentStatusEnum } from '../api/Ferrolog'
import { readableLookupDict } from '../stores/EquipmentStatusStore'
import { getLatestActiveStatusFromList } from '../EquipmentStatus/EquipmentStatusHelpers'

const allStates = {
    'Calibrated': (<option key={'il-1'} value={'Calibrated'}>{readableLookupDict['Calibrated']}</option>),
    'CalibrationRequired': (<option key={'il-2'} value={'CalibrationRequired'}>{readableLookupDict['CalibrationRequired']}</option>),
    'SentForCalibration': (<option key={'il-3'} value={'SentForCalibration'}>{readableLookupDict['SentForCalibration']}</option>),
    'Approved': (<option key={'il-4'} value={'Approved'}>{readableLookupDict['Approved']}</option>),
    'OnHold': (<option key={'il-5'} value={'OnHold'}>{readableLookupDict['OnHold']}</option>),
    'Discarded': (<option key={'il-6'} value={'Discarded'}>{readableLookupDict['Discarded']}</option>),
    'Purchased': (<option key={'il-7'} value={'Purchased'}>{readableLookupDict['Purchased']}</option>)
}

const transitions = {
    'All': [EquipmentStatusEnum.Calibrated, EquipmentStatusEnum.CalibrationRequired, EquipmentStatusEnum.SentForCalibration, EquipmentStatusEnum.Approved, EquipmentStatusEnum.OnHold, EquipmentStatusEnum.Discarded, EquipmentStatusEnum.Purchased],
    'Calibrated': [EquipmentStatusEnum.Calibrated, EquipmentStatusEnum.Approved, EquipmentStatusEnum.OnHold],
    'CalibrationRequired': [EquipmentStatusEnum.SentForCalibration, EquipmentStatusEnum.OnHold],
    'SentForCalibration': [EquipmentStatusEnum.Calibrated, EquipmentStatusEnum.OnHold],
    'Approved': [EquipmentStatusEnum.CalibrationRequired, EquipmentStatusEnum.SentForCalibration, EquipmentStatusEnum.OnHold],
    'OnHold': [EquipmentStatusEnum.Calibrated, EquipmentStatusEnum.CalibrationRequired, EquipmentStatusEnum.SentForCalibration, EquipmentStatusEnum.Approved, EquipmentStatusEnum.OnHold],
    'Discarded': [EquipmentStatusEnum.OnHold],
    'Purchased': [EquipmentStatusEnum.Calibrated, EquipmentStatusEnum.CalibrationRequired, EquipmentStatusEnum.SentForCalibration, EquipmentStatusEnum.Approved, EquipmentStatusEnum.OnHold]
}

export const getPossibleNewStates = (existingStatuses: EquipmentStatusDto[] | undefined): EquipmentStatusEnum[] => {
    const latestStatus = getLatestActiveStatusFromList(existingStatuses!)

    let allowedStates = transitions[latestStatus?.status ?? 'All']

    if (!existingStatuses?.find(es => es.status === EquipmentStatusEnum.Purchased) &&
        !allowedStates.find(s => s === EquipmentStatusEnum.Purchased))
        allowedStates.push(EquipmentStatusEnum.Purchased)

    return allowedStates
}

/**
 * Extend properties
 */
export interface EquipmentStatusSelectProps extends SelectProps {
    value: CalibrationIntervalEnum | string | null | undefined
    existingStatuses?: EquipmentStatusDto[]
}

export const EquipmentStatusSelect: React.FunctionComponent<EquipmentStatusSelectProps> = (props) => {
    const { value, existingStatuses, disabled, ...otherProps } = props

    let finalProps = Object.assign({
        value: value,
        native: true,
        disabled: disabled,
    }, otherProps)

    const getStatusList = (statuses: EquipmentStatusEnum[]): JSX.Element[] => statuses.map(s => allStates[s])

    const filteredStats = !existingStatuses || disabled || existingStatuses.length === 0
        ? getStatusList(transitions.All)
        : getStatusList(getPossibleNewStates(existingStatuses))

    return (
        <Select {...finalProps} >
            {filteredStats}
            {props.children}
        </Select>
    )
}